.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 1;
  z-index: 1300;
}

.dialog {
  width: 600px;
  max-width: 600px;
  margin: auto;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding: 24px 0px;
  gap: 16px;
  opacity: 1;
}

.dialog-title-text {
  width: 496px;
  height: 30px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.025em;
  text-align: left;
  color: #27282b;
}

.icon-container {
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 100px;
}

.icon {
  width: 24px;
  height: 24px;
  top: 6px;
  left: 6px;
  border: 2px;
  color: #345ba9;
}

.dialog-content {
  width: 100%;
}

.dialog-space {
  margin-bottom: 16px;
}

.dialog-description p {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  color: #323452;
}

.mfa-button {
  width: 100% !important;
  height: 48px;
  padding: 8px 24px !important;
  border-radius: 4px;
  font-family: "Barlow Semi Condensed", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: center;
  opacity: 1;

  @media (max-width: 768px) {
    height: 50px;
    font-size: 14px !important;
    padding: 6px 16px !important;
  }
}

.mfa-button:not(:disabled) {
  background-color: #ffffff;
  cursor: pointer;
}

.button-content {
  width: 100%;
  /* height: 32px; */
  display: flex;
  gap: 16px;
}

.button-icon {
  width: 32px;
  height: 32px;
  @media (max-width: 768px) {
    width: 26px;
    height: 26px;
  }
}

.button-text {
  width: 446px;
  height: 23px;
  font-family: Barlow Semi Condensed;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  color: #191d23;
  text-transform: none;
  @media (max-width: 768px) {
    font-size: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 30px;
  }
}

.checkbox-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 768px) {
    margin-top: 16px;
  }
}

.check {
  width: 27px;
  height: 24px;
  padding: 0px 28.65px 0px 0px;

  @media (max-width: 768px) {
    width: 18px;
    height: 18px;
  }
}

.rem-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  margin-left: 5px;
  @media (max-width: 768px) {
    font-size: 15px;
  }
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
.mfa-save {
  width: 100%;
  text-align: right;
  padding: 28px 0;
  @media (max-width: 768px) {
    padding: 15px 0;
  }

  .cancelBtn {
    background-color: #fff;
    color: #086dab;
    font-weight: 700;
    font-size: 18px;
    text-transform: none;
    border: none;
    font-family: "Barlow Semi Condensed", sans-serif;
  }

  .save {
    font-size: 18px;
    font-weight: 700;
    text-transform: none;
    background-color: #086dab;
    border-radius: 6px;
    font-family: "Barlow Semi Condensed", sans-serif;
    padding: 7px 16px;
    @media (max-width: 768px) {
      margin: 0px 15px !important;
      font-size: 17px;
    }
  }
}
.succes_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    height: 73px;
    margin-bottom: 8px;
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 0;
  }
}
.barcodeScreen {
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
  }
}
.backup-content {
  .tagLine {
    font-size: 24px;
  }
}

.progress {
  margin-top: 20px;
}

.progress-bar {
  width: auto;
  height: 10px;
  border-radius: 20px;
  background-color: #D9D9D9 !important;
}

.progress-text {
  font-family: Barlow Semi Condensed;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
}

@media (max-width: 767px) {
  .mfa_dailog_option .MuiDialog-paper {
    width: 100%;
    max-width: 310px;
  }
  .dialog {
    width: 100%;
  }
  .dialog-title {
    height: auto;
  }
  .dialog-space {
    height: auto;
  }
  .button-content {
    gap: 3px;
  }
  .dialog-content {
    padding: 0px !important;
  }
}
