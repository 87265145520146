.healthStatusWrapper {
    width: 100%;
    .main_heading {
      border-bottom: 1px solid rgb(230, 236, 245);
      padding: 8px 24px;
      display: flex;
      @media (max-width: 768px) {
        margin-top: 50px;
    }
  
      h1 {
        color: rgba(3, 107, 173, 1);
        font-weight: 700;
        font-size: 32px;
        line-height: 38.4px;
        margin-bottom: 0;
      }
    }
  
    .inner_Wrapper {
      margin: 19px 24px;
      &.healthStatus_innerwrap{
        background: #FAFAFA;
        padding: 21px;
        .statusColorWrapper{
          ul{
              list-style-type: none;
              padding: 0;
              margin: 0;
              display: flex;
              justify-content: flex-end;
              margin-bottom: 17px;
              padding-right: 7px;
            li{
              color: #000;
              font-weight: 400;
              font-size: 14px;
              line-height: 14px;
              text-align: right;
              margin-left: 20px;
              position: relative;
                display: flex;
                align-items: center;
              .healthy{
                background: #037E3A;
                border: 1px solid #025427;
              }
              .warning{
                background: #FF9900;
                border: 1px solid #A16101;
              }
              .critical{
                background: #CD3F00;
                border: 1px solid #6D2201;
              }
              span{
                width:40px;
                height: 18px;
                display: inline-block;
                margin-right: 6px;
                border-radius: 4px;
              }
              
            }
          }
        }
        .healthCard_wrapper{
          .healthCard{
              box-shadow: 0px 1px 50px 0px #00000014;
              border: 1px solid #E8E7E7;
              background: #FFFFFF;
              border-radius: 12px;
              padding: 20px 26px 10px 26px;
              margin: 17px 0;
              width: 100%;
              text-align: left;
              &.disabled {
                cursor: not-allowed;
              }
              .statusWrapper{
                display: flex;
                width: 100%;
                justify-content: space-between;
                .statusShow{
                  display: flex;
                  align-items: center;
                  span{
                    img{
                      width: 14px;
                      height: 14px;
                      margin-right: 4px;
                    }
                  }
                  p{
                    padding: 0;
                    margin: 0;
                    width: auto;
                  }
                }
              }
              img{
                height: 22px;
                width: 22px;
              }
              h3{
                color: #272927;
                font-weight: 600;
                font-size: 32px;
                line-height: 38px;
                margin-top: 15px; 
                margin-bottom: 66px;
                white-space: nowrap;
              }
              .healtBeat{
                display: flex;
                align-items: center;
                border-radius:8px; 
                padding: 4px 8px;
                p{
                  color: #000000;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 14px;
                  border-radius: 4px;
                  text-transform: capitalize;
                  margin-bottom: 0;
                  margin-left: 10px;
                }
              }
            
              .warningBg{
                background: #F8DEBD;
              }
              .healthyBg{
                background: #C5F8DD;
              }
              .criticalBg{
                background: #FBF0F3;
              }
          }
        }
      }
    }
  }

  .detailedViewCards{
    box-shadow: 0px 0px 0px 1px #E3E4E6;
    border-radius: 6px;
    margin-top: 40px;
    .MuiDataGrid-columnHeaderTitle{
      color: #1D2939;
      font-size: 16px;
      font-weight: 700;
      font-family: "Barlow Semi Condensed", sans-serif !important;
    }
    .MuiDataGrid-cell{
      color: #1D2939;
      font-size: 16px;
      font-weight: 400;
      font-family: "Barlow Semi Condensed", sans-serif !important;
    }
    .MuiDataGrid-columnSeparator{
      display: none !important;
    }
  }

  .arrowHandlerBtn{
    background: transparent;
    padding: 0;
    margin: 0;
    margin-right: 34px;
  }
  .status-green{color:#000000; background: #D6FFE8;  border-radius: 6px; width: 80px; height: 40px;    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;}
  .status-red{color:#000000; background:#FFDACA ; border-radius: 6px; width: 80px;height: 40px;    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;}