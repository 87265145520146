.footer_wrapper{
    background: rgba(230, 236, 245, 1);
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 24px;
    height: 45px;
    border-radius: 4px;
    justify-content: space-between;
    ul.leftMenu{
        list-style-type: none;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding-left: 0;
        li{
           
            a{
                height: 24px;
                width:24px;
                display: flex;
                margin-right: 8px;
                cursor: default;
                pointer-events: none;
                &.map{
                     background: url('../../assets/images/map.svg');
                    //  &:hover{
                    //     background: url('../../assets/images/map_hover.svg');
                    //  }
                 }
                 &.list{
                    background: url('../../assets/images/list.svg');
                    // &:hover{
                    //     background: url('../../assets/images/list_hover.svg');
                    //  }
                }
            }
        
        }
    }
    ul.centerMenu{
        list-style-type: none;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding-left: 0;
        li{
            display: flex;
            align-items: center;
            // cursor: pointer;
            a{
                height: 40px;
                width:40px;
                display: flex;
                margin-left: 4px;
                margin-right: 4px;
                cursor: default;
                &.strobe-Button{
                     background: url('../../assets/images/strobe-Button.svg');
                 }
                 &.Push-Button{
                    background: url('../../assets/images/Push-Button.svg');
                }
                &.Speaker-Button{
                    background: url('../../assets/images/Speaker-Button.svg');
                }
                &.Camera-Button{
                    background: url('../../assets/images/Camera-Button.svg');
                }
                &.repair-Button{
                    background: url('../../assets/images/repair-Button.svg');
                }
              
            }
            span{
                cursor: default;
            }
        
        }
    }
    ul.rightMenu{
        list-style-type: none;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding-left: 0;
        li{
            a{
                height: 24px;
                width:24px;
                display: flex;
                margin-left: 8px;
                cursor: default;
                &.zoom_in{
                     background: url('../../assets/images/zoom_in.svg');
                    &:hover{
                        background: url('../../assets/images/zoom_in_hover.svg');
                    }
                    cursor: pointer;
                }

                &.zoom_out{
                    background: url('../../assets/images/zoom_out.svg');
                    &:hover{
                        background: url('../../assets/images/zoom_out_hover.svg');
                    }
                    cursor: pointer;
                }
                &.fullscreen{
                    background: url('../../assets/images/fullscreen.svg');
                    &:hover{
                        background: url('../../assets/images/fullscreen_hover.svg');
                    }
                    cursor: pointer;
                }
            }
        }
    }
}

@media (max-width: 1198px) {
    .footer_wrapper {
        gap: 15px;
        justify-content: space-between !important;

        ul.leftMenu {
            font-size: 14px;
            display: flex !important;
        }

        ul.centerMenu {
            display: none;
        }

        ul.rightMenu {
            display: flex !important;
            .css-15gm0yv-MuiSlider-root, .css-1tiwfbw, button{
                display: none;
            }
        }
    }
}