.add-devices-container {
  display: flex;
  gap: 20px;
}

.sidebar {
  width: 200px;
  padding: 10px;
  border-right: 1px solid #ccc;
}

.device-icon {
  margin: 10px 0;
  cursor: pointer;
  padding: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
}

.floor-map {
  width: 600px;
  height: 400px;
  background-color: #f0f0f0;
  position: relative;
  border: 1px solid #ccc;
}

.device {
  position: absolute;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: move;
}

.actions {
  margin-top: 20px;
}

button {
  margin-right: 10px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.floor-dropdown.MuiBox-root {
  min-width: 115px !important;
  display: flex;

  .undoRdoBtn {
    display: flex;

    button {
      margin-left: 10px;
      padding: 0 0;
      background: transparent;
      margin-bottom: -4px;
      opacity: 1;
    }

    button:disabled {
      opacity: 0.6;
    }
  }
}

.addDeviceInnerWrppar {
  .mapwrapper {
    display: flex;
    //min-width: 900px;
    height: 100%;

    @media (max-width: 786px) {
      flex-direction: column;
      position: relative;
    }

    .inner_addDevicewrapper {
      height: 550px;
      padding: 0px 0;

      @media (max-width: 768px) {
        overflow: scroll;
        width: 1000px !important;
      }

      @media (min-width: 769px) and (max-width:1198px) {
        overflow: auto;
      }  
    }
  }
}

.MuiDataGrid-root {
  border: none !important;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards; // Adjust the duration as needed
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.overlay-wrapper {
  overflow: auto;

  @media(max-width:768px) {
    overflow: auto;
  }
}

.overlay-wrapper::-webkit-scrollbar {
  width: 7px;
  /* Width of the vertical scrollbar */
  height: 7px;
  /* Height of the horizontal scrollbar */
}

.overlay-wrapper::-webkit-scrollbar-track {
  background: #f0f0f0;
  /* Background color of the scrollbar track */
  border-radius: 5px;
}

.overlay-wrapper::-webkit-scrollbar-thumb {
  background: #8f8888;
  /* Color of the scrollbar thumb */
  border-radius: 5px;
  /* Rounded edges */
}

.overlay-wrapper::-webkit-scrollbar-thumb:hover {
  background: #5a5656;
  /* Darker shade for hover */
}

.overlay-wrapper::-webkit-scrollbar-corner {
  background: #f0f0f0;
  /* Color of the scrollbar corner */
}

.addDevice_flor_drop {
  justify-content: flex-end !important;
}

.dropedItems {
  .deleteIcon {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 19px;
    top: -2px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #000;
    cursor: pointer;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2509803922);

    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 2.5px;
      left: 2.5px;
      top: 5px;
      background: #000;
    }
  }
}

.main_heading_addDevices {
  flex-direction: column;
  align-items: flex-start !important;
}