.floorMap_wrapper{
    width: 100%;
    // padding: 41px 62px;
    // border-left: 1px solid #000;
    .main_heading{
        border-bottom: 1px solid rgb(230, 236, 245);
        padding:8px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1{
            color:rgba(3, 107, 173, 1);
            font-weight: 700;
            font-size: 32px;
            line-height:38.4px;
        }
        .blinkText{
            animation: textOpacity 1s infinite;
            @keyframes textOpacity {
                0% {
                  opacity: 1;
                }
                25% {
                    opacity: 0;
                }
                50% {
                    opacity: 1;
                }
                75% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
            p{
                color: #e23636;
                font-weight: 700;
                font-size: 32px;
                line-height: 38.4px;
            }
            
        }
    }
    .floorTab{
        width: 100%;
        // height: 37px;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        padding: 18px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgb(230, 236, 245);
        ul{
            display: flex;
            list-style-type: none;
            align-items: center;
            height: 100%;
            margin-bottom: 0;
            padding-left: 0;
            li{
                a{
                    font-size: 22px;
                    line-height: 26px;
                    font-weight: 400;
                    padding: 4px 4px;
                    border-bottom: 2px solid transparent;
                    margin-right: 10px;
                    color: rgba(78, 78, 78, 1);
                    cursor: pointer;
                    text-decoration:none;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 150px;
                    display: inline-block;
                    vertical-align: middle;   
                    &:hover{
                        border-bottom: 2px solid rgba(3, 107, 173, 1);
                        color: rgba(39, 40, 43, 1);
                    }
                    &.active{
                        border-bottom: 2px solid rgba(3, 107, 173, 1);
                        color: rgba(39, 40, 43, 1);
                    }
                    &.disabled{
                        pointer-events: none;
                        cursor: default;
                    }
                    .alerticon{
                        height: 20px;
                        width: 20px;
                        margin-left: 5px;
                    }
                }
            }
        }
        .resetBtn{
            background: #036bad;
            border: none;
            color: #fff;
            padding: 5px 14px;
            border-radius: 5px;
            cursor: pointer;
            border: 2px solid transparent;
            font-size: 18px;
            font-weight: 700;
            // &:hover{
            //     background: #fff;
            //     color: #036bad;
            //     border: 2px solid #000;

            // }
        }
    }
    .innerWrapper{
        margin:19px 24px;
        border: 1px solid rgba(230,236,245,1);
        border-radius: 6px;
     
    }.bottom_wrapper{
         padding:0px 16px 5px 16px !important;
        .floorOne{
            img{
                height: 100%;
                width: 100%;
            }
            &.mapOne{
                background-image: url('../../assets/images/floorOneMap.png');
                &.forPrintView{
                    background-image: url('../../assets/images/opacityOne.png');
                }
                &.specialClass{
                    background-image: url('../../assets/images/opacityOne.png');
                }
            }
            &.mapTwo{
                background-image: url('../../assets/images/floorTwoMap.png');
                &.forPrintView{
                    background-image: url('../../assets/images/opacityTwo.png');
                }
                &.specialClass{
                    background-image: url('../../assets/images/opacityTwo.png');
                }
            }
            &.addHighlight1{
                background-image: url('../../assets/images/addHighlightOne.png');
            }
            &.addHighlight2{
                background-image: url('../../assets/images/addHighlightTwo.png');
            }
            // max-width: 1044px;
            // width: 100%;
            height: 615px;
            width: 100%;
            // background-size: contain;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: relative;
            margin: 25px auto;
        }
    }
   
    .signBtn{
        border: none;
        background: transparent;
        position: absolute;       
        cursor: pointer;
    }
    .exitSign{
        background-image: url('../../assets/images/exitSign.png');
        background-repeat: no-repeat; 
        background-size: cover;
        padding: 0 0;
        width: 22.084px;
        height: 26.146px;
        bottom: 38%;
        left: 57%;
    }
    .camerBtn{
        background-image: url('../../assets/images/cameraButton.svg');
        background-repeat: no-repeat; 
        background-size: cover;
        padding: 0 0;
        width: 44px;
        height: 44px;
        cursor: default;
        
        // &:hover{
            //     background-image: url('../../assets/images/cameraButtonHover.svg');
            //     border-radius:50%; 
            //     width: 44px;
            //     height: 44px;
            // }
            &.activated{
                animation: Dissolve 900ms ease-in-out 200ms infinite;
                cursor: pointer;
                @keyframes Dissolve {
                    0% {
                        background-image: url('../../assets/images/cameraButtonactiv1.svg');
                    }
                    33% {
                        background-image: url('../../assets/images/cameraButtonactiv2.svg');
                    }
                    67%{
                        background-image: url('../../assets/images/cameraButtonactiv3.svg');
                    }
                    100% {
                        background-image: url('../../assets/images/cameraButtonactiv1.svg');
                }
            }
        }
        &.cameraBroken{
            background-image: url('../../assets/images/cameraBroken.svg');
        }
        &.active{
            background-image: url('../../assets/images/cameraButtonPressed.svg');
        }
        // &.one{
        //     bottom: 35%;
        //     left: 8.5%;
        // }
        // &.two{
        //     bottom: 54%;
        //     left: 8.5%;
        // }
        // &.three{
        //     bottom: 54%;
        //     left: 52%;
        // }
        // &.activated:before {
        //     animation:ripple 2s ease-out infinite;
        //     border:solid 3px #00669B;
        //     border-radius: 50%;
        //     bottom:0;
        //     box-sizing:border-box;
        //     content:"";
        //     left:0;
        //     position:absolute;
        //     right:0;
        //     top:0;
        //   }
          
        //   &.activated:after {
        //     animation:ripple 2s ease-out infinite;
        //     border:solid 3px #00669B;
        //     border-radius: 50%;
        //     bottom:0;
        //     box-sizing:border-box;
        //     content:"";
        //     left:0;
        //     position:absolute;
        //     right:0;
        //     top:0;
        //   }
          
        //   @keyframes ripple {
        //     0% {
        //       opacity:.25;
        //     }
        //     100% {
        //       border-radius:2em;
        //       opacity:0;
        //       transform:scale(1.5);
        //     }
        //   }
    }
    .cameraButtonFlip{
        background-image: url('../../assets/images/cameraButtonFlip.svg');
        background-repeat: no-repeat; 
        background-size: cover;
        padding: 0 0;
        width: 44px;
        height: 44px;
        cursor: default;
        // &:hover{
        //     background-image: url('../../assets/images/cameraButtonFlipHover.svg');
        //     border-radius:50%; 
        //     width: 44px;
        //     height: 44px;
        // }
        &.active{
            background-image: url('../../assets/images/cameraButtonFlipPressed.svg');
        }
        // &.one{
        //     bottom: 35%;
        //     left: 8.5%;
        // }
        // &.two{
        //     bottom: 54%;
        //     left: 8.5%;
        // }
        // &.three{
        //     bottom: 54%;
        //     left: 52%;
        // }
    }
    .commonLight{
        background-image: url('../../assets/images/strobeButton.svg');
        background-repeat: no-repeat;
        width: 44px;
        height: 44px;
        background-size: cover;
        padding: 0 0;
        bottom: 63%;
        left: 45.5%;
        cursor: default;

        // &:hover{
        //     background-image: url('../../assets/images/strobeButtonHover.svg');
        //     border-radius:50%; 
        //     width: 44px;
        //     height: 44px;
        // }
        &.activated{
            animation: SmartAnimate 600ms ease-in-out 100ms infinite;
            cursor: pointer;
            @keyframes SmartAnimate {
                0% {
                    background-image: url('../../assets/images/strobeButtonactiv1.svg');
                }
                33% {
                    background-image: url('../../assets/images/strobeButtonactiv2.svg');
                }
                67%{
                    background-image: url('../../assets/images/strobeButtonactiv3.svg');
                }
                100% {
                    background-image: url('../../assets/images/strobeButtonactiv1.svg');
            }
        }
        }
        &.active{
            background-image: url('../../assets/images/strobeButtonPressed.svg');
        }
        // &.activated:before {
        //     animation:ripple 2s ease-out infinite;
        //     border:solid 3px #AD5F04;
        //     border-radius: 50%;
        //     bottom:0;
        //     box-sizing:border-box;
        //     content:"";
        //     left:0;
        //     position:absolute;
        //     right:0;
        //     top:0;
        //   }
          
        //   &.activated:after {
        //     animation:ripple 2s ease-out infinite;
        //     border:solid 3px #AD5F04;
        //     border-radius: 50%;
        //     bottom:0;
        //     box-sizing:border-box;
        //     content:"";
        //     left:0;
        //     position:absolute;
        //     right:0;
        //     top:0;
        //   }
          
        //   @keyframes ripple {
        //     0% {
        //       opacity:.25;
        //     }
        //     100% {
        //       border-radius:2em;
        //       opacity:0;
        //       transform:scale(1.5);
        //     }
        //   }
    }
    .pushBtn{
         background-image: url('../../assets/images/PushButton.svg');
        background-repeat: no-repeat;
        width: 44px;
        height: 44px;
        background-size: cover;
        padding: 0 0;
        bottom: 66%;
        left: 8.5%;
        cursor: default;
        // &:hover{
        //     background-image: url('../../assets/images/PushButtonHover.svg');
        //     border-radius:50%; 
        //     width: 44px;
        //     height: 44px;
        // }
        &.active{
            background-image: url('../../assets/images/PushButtonPressedActivated.svg');
        }
        &.pushBroken{
            background-image: url('../../assets/images/PushButtonBroken.svg');
            cursor:auto;
        }
        &.activated{
            animation: SmartAnimatePushBtn 600ms ease-in-out 100ms infinite;
            cursor: pointer;
            @keyframes SmartAnimatePushBtn {
                0% {
                    background-image: url('../../assets/images/pushButtonactiv1.svg');
                }
                33% {
                    background-image: url('../../assets/images/pushButtonactiv2.svg');
                }
                67%{
                    background-image: url('../../assets/images/pushButtonactiv3.svg');
                }
                100% {
                    background-image: url('../../assets/images/pushButtonactiv1.svg');
            }
        }
        }
        &.two{
            bottom: 75%;
            left: 50%;
        }
    }
    
    .audioIcon{
        background-image: url('../../assets/images/SpeakerButton.svg');
        background-repeat: no-repeat;
        width: 44px;
        height: 44px;
        background-size: cover;
        padding: 0 0;
        left: 2%;
        cursor: default;
        position: relative;
        // &:hover{
        //     background-image: url('../../assets/images/SpeakerButtonHover.svg');
        //     border-radius:50%; 
        //     width: 44px;
        //     height: 44px;
        // }
        span{
            display: none;
        }
        &.activated{
            animation: SmartAnimateSpeaker 900ms ease-in-out 200ms infinite;
            cursor: pointer;
            @keyframes SmartAnimateSpeaker {
                0% {
                    background-image: url('../../assets/images/SpeakerButtonactiv1.svg');
                }
                33% {
                    background-image: url('../../assets/images/SpeakerButtonactiv2.svg');
                }
                67%{
                    background-image: url('../../assets/images/SpeakerButtonactiv3.svg');
                }
                100% {
                    background-image: url('../../assets/images/SpeakerButtonactiv1.svg');
            }
        }
        }
        &.active{
            background-image: url('../../assets/images/SpeakerButtonPressed.svg');
        }
        // &.one{
        //     bottom: 19%;
        // }
        // &.two{
        //     bottom: 34%;
        // }
        // &.three{
        //     bottom: 49%;
        // }
        // &.four{
        //     bottom: 65%;
        // }
        // &.five{
        //     bottom: 70%;
        //     left: 56%;
        // }
        // &.activated:before {
        //     animation:ripple 2s ease-out infinite;
        //     border:solid 3px #9E00FF;
        //     border-radius: 50%;
        //     bottom:0;
        //     box-sizing:border-box;
        //     content:"";
        //     left:0;
        //     position:absolute;
        //     right:0;
        //     top:0;
        //   }
          
        //   &.activated:after {
        //     animation:ripple 2s ease-out infinite;
        //     border:solid 3px #9E00FF;
        //     border-radius: 50%;
        //     bottom:0;
        //     box-sizing:border-box;
        //     content:"";
        //     left:0;
        //     position:absolute;
        //     right:0;
        //     top:0;
        //   }
          
        //   @keyframes ripple {
        //     0% {
        //       opacity:.25;
        //     }
        //     100% {
        //       border-radius:2em;
        //       opacity:0;
        //       transform:scale(1.5);
        //     }
        //   }
    }

}


.showFloor{
    display: none;
    font-size: 20px;
    font-weight: 600;
    margin-left: 20px;
    &.active{
        display: block;
    }
}
.customTooltip{
    background: #27282B !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 9px 10px !important;
    border-radius: 10px !important;
    margin-bottom:5px !important 

}
.customArrow::before{
    background: #27282B !important;
}

.map_wrp_inout{
    overflow: auto;
    width: 100%;
    height: 680px;
}

/* Custom Scrollbar */
.map_wrp_inout::-webkit-scrollbar {
    width: 6px; /* Width of the vertical scrollbar */
    height: 6px; /* Height of the horizontal scrollbar */
}

.map_wrp_inout::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Make it rounded */
}

.map_wrp_inout::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Darker color on hover */
}

.map_wrp_inout::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
    border-radius: 10px;
}

/* Remove scrollbar buttons (arrows) */
.map_wrp_inout::-webkit-scrollbar-button {
    display: none;
}

.customTooltip.overRideTooltip{
    box-shadow: 0px 0px 12px 0px #00000040;
    background: #000000 !important;
    color: #fff !important;
    display: flex;
    z-index: 9;
    font-size: 18px !important;
    span{
        font-size: 16px;
        font-weight: 400;
    }
    .MuiSwitch-root{
        background: #E5E7EB;
    }
    .MuiStack-root {
        margin-left: 17px !important;
    }
}

.camerabtnsaturated{
    filter: saturate(0);
    animation: none !important;
    cursor: auto !important;
}

.dispNone{
    display: none !important;
}

.activatedFloor{
    position: absolute;
    bottom: 25px;
}