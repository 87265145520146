/* .dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 0px;
  gap: 16px;
  opacity: 1;
}

.dialog-title-text {
  width: 496px;
  height: 30px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.025em;
  text-align: left;
  color: #27282b;
} */

.icon-container {
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 100px;
}

.icon {
  width: 24px;
  height: 24px;
  top: 6px;
  left: 6px;
  border: 2px;
  color: #345ba9;
}

.dialog-content {
  width: 100%;
  color: #323452;
  font-size: 16px;
}

button {
  width: max-content;
}

.align {
  display: flex;
  width: 389px;
  height: 50px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.label-text {
  color: #4e4e4e;
  font-family: Barlow Semi Condensed;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.0275em;
  text-align: left;
  margin-bottom: 10px;
}

.authenticator-input .text-box {
  display: flex;
  align-items: center;
  width: 274px;
  height: 44px;

  padding:10px;
  border-radius: 6px;
  border: 1px solid #76778b;
  background-color: white;
  margin-right: 20px;
}
.plc-text {
  font-family: Barlow Semi Condensed;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 5px;
  color: #27282b;
  text-align: center;
}

.loginViacode{
  color: #0066FF;
  font-family: Barlow Semi Condensed;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  padding-top: 10px;
  cursor: pointer;
}

.authenticator-input input:focus {
  border-color: #3f51b5;
  box-shadow: 0 0 4px rgba(63, 81, 181, 0.2);
}

.send-code-button {
  height: 44px;
  border-radius: 6px;
}

.btn-text {
  text-transform: none !important;
  font-family: Barlow Semi Condensed !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 28px !important;
  text-align: center;
  color: #086dab !important;
}

.btn-text:focus {
  outline-offset: 2px;
}

.success-dialog {
  width: 600px;
  height: 148px;
  padding: 0px 32px 0px 32px;
  gap: 16px;
}

.success-box {
  text-align: center;
  width: 536px;
  height: 148px;
  gap: 8px;
}

.success-box img {
  width: 73px;
  height: 73px;
  margin-bottom: 10px;
}

.success-box .h2 {
  width: 536px;
  height: 36px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}

.success-box .p {
  font-family: Barlow Semi Condensed;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  width: 406px;
  height: 23px;
}
.disbleBtn {
  color: #eaecf0 !important;
}
.disabled-button {
  text-transform: none !important;
  font-family: Barlow Semi Condensed !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  text-align: center;
}

.disabled-button:enabled {
  color: white;
  background-color: #086dab;
}

.align-buttons {
  width: 447px;
  height: 50px;
  display: flex;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.emailDailog_wrapper {
  padding: 0 0 !important;
}

@media (max-width: 768px) {
  .text-box {
    max-width: 100%;
    font-size: 14px;
    margin-right: 10px !important;
    width: 120px !important;
  }

  .send-code-button {
    display: flex;
  }

  .btn-text {
    width: 100%;
    font-size: 14px;
    padding: 8px;
  }
}

.done-btn {
  background-color: #086dab !important;
  text-transform: none !important;
  font-family: Barlow Semi Condensed !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  text-align: center !important;
  padding-top: 8px !important;
  padding-right: 16px !important;
  padding-bottom: 8px !important;
  padding-left: 16px !important;
  border-radius: 6px !important;
}
