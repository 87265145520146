.loginWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    .loginFeild{
        width: 400px;
        height: 280px;    
        border-radius: 15px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 13px 8px rgba(0,0,0,0.14);
        -moz-box-shadow: 0px 0px 13px 8px rgba(0,0,0,0.14);
        box-shadow: 0px 0px 13px 8px rgba(0,0,0,0.14);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 0 40px;
        .field{
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            label{
                width: 100%;
                margin-bottom: 15px;
                color: #414141;
                font-weight: 500;
            }
            // input{
            //     width: 100%;
            //     border: 2px solid #888888;
            //     border-radius: 5px;
            //     padding: 10px 10px;
            //     font-size: 30px;
            // }
            .btnSubmit{
                width: 100%;
                height: 45px;
                font-size: 18px;
                color: #fff;
                margin-top: 15px;
                background: #000;
                border-radius: 5px;
                border: transparent;
                -webkit-box-shadow: 0px 0px 13px 2px rgba(0,0,0,0.14);
                -moz-box-shadow: 0px 0px 13px 2px rgba(0,0,0,0.14);
                box-shadow: 0px 0px 13px 2px rgba(0,0,0,0.14);
                cursor: pointer;
            }
        }

    }
}

.Login_container {
    background-image: url('../../assets/images/loginWrapper.svg');
    background-color: #004c80;
    padding: 66px 0px 0px 0px;
    // height: 100%;
    min-height: 580px;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width:1199px) {
       min-height: 90%;
       padding: 44px 34px 145px 34px;
       background-position: center;
    }

}
// .Login_container.mainLoginPage{  min-height: 580px;}
// .Login_container.forgetPage{  max-height: 580px;}
.header{    
    .logoImg{
        background-image: url('../../assets/images/logoMain.svg');
        width: 100%;
        height: 82px;
        background-repeat: no-repeat;
        display: inline-flex;
        @media (max-width:768px) {
            height: 50px;
            background-size: contain;
        }
    }
    .needAndsupport{
        text-align: right;
        @media (max-width:1199px) {
            text-align: left;
        }
        @media (max-width:768px) {
            margin-top: 32px;
            text-align: left;
        }
       .needsupportAnchor{
            text-decoration: none;
            color:#fff;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 0;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}
.left-section {
    color: white;
    // padding: 0 50px  50px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    padding-top: 50px;
    @media (max-width:768px) {
        display: none;
    }
}
.right_wrapper{
    display: flex;
    justify-content: flex-end;
    @media (max-width: 1199px){
        justify-content: center;   
    }
    .right-section {
        // width: 448px;
        position: absolute;
        width: 448px;
        margin-top: 50px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 4px 10px 4px #0000001A;
        border-radius: 6px;
        padding: 48px;
        @media (max-width:1199px) {
            position: relative;
            margin-top: 102px;
            padding: 20px 38px 20px 38px;
        }
    }
}


.branding {
    max-width: 600px;
}
.paragraphForgot{
    color:#323452;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
}
.branding h1 {
    font-size: 36px;
    margin-bottom: 20px;
}

.logo-part {
    color: #00aaff;
}

.highlight {
    color: #cd3f00;
    font-weight: bold;
}

.tagline {
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    margin-bottom: 0;
}

.description {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 0;
}

.know-more-btn {
    background-color: #CD3F00;
    color: white;
    border: 1px solid #27282B;
    padding: 7px 13px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin-top: 15px;
    border-radius: 4px;
    font-family: "Barlow Semi Condensed", sans-serif !important;
    // cursor: default !important;
    text-decoration: none;
    display: inline-block;
}

.login-form {
    width: 100%;
   
}

.login-form h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: left;
    color:#4E4E4E;
    font-weight: 700;
}

.login-form label {
    font-size: 16px;
    display: block;
    margin-bottom: 5px;
    color: #4E4E4E;
    font-weight: 400;
    position: relative;
    font-family: "Barlow Semi Condensed", sans-serif !important;
}

.login-form .MuiFormControl-root {
    width: 100% !important;
    margin: 0 0 8px 0 !important;
    input{
        border-radius: 4px;
        font-size: 14px;
        font-family: "Barlow Semi Condensed", sans-serif !important;
        padding: 12px 12px !important;
    }
}
.login-form  legend{
    display: none;
}
.login-form button.btnSubmit {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 22px;
    background: #086DAB;
    color:#fff;
    font-weight: 700;
    cursor: pointer;
}

.forgot-password {
    display: inline-block;
    text-align: left;
    margin-bottom: 34px;
    color: #036BAD;
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.4px;
}

.forgot-password:hover {
    text-decoration: underline;
}

.sign-in-btn {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
}
.errorwraper{
    margin-top: 10px;
    width: 100%;
    height: 10px;
    display: inline-block;
    .errorMessage{
        color: red;
       
    }
}
.or-divider {
    display: flex;
    align-items: center;
    margin: 20px 0;
    color: #888;
}

.or-divider span {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #aaa;
    position: relative;
}

.or-divider span::before,
.or-divider span::after {
    content: "";
    height: 1px;
    background-color: #ddd;
    position: absolute;
    top: 50%;
    width: 45%;
}

.or-divider span::before {
    left: 0;
}

.or-divider span::after {
    right: 0;
}

.google-sign-in {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}

.google-sign-in .google-logo {
    width: 20px;
    margin-right: 10px;
}

footer {
    padding: 40px 0px 40px 0px;
    color: #004c80;
    font-size: 14px;
    height: 215px;
    display: flex;
    align-items: flex-end;
    @media (max-width: 1199px){
        padding: 10px 0px 28px 0px;
        height: auto;
        .left{
            text-align: center;
            margin-bottom: 10px !important;
        }
        .right{
            justify-content: center !important;
        }
    }
    @media (max-width:768px) {
        padding: 10px 0px 28px 0px; 
        height: auto;    
    }
    .left{
        font-size: 22px;
        color: #036BAD;
        font-weight: 400;
        margin-bottom: 0;
        cursor: default;
        @media (max-width:768px) {
         text-align: center;  
         margin-bottom: 10px;  
        }
    }
    .right{
        font-size: 14px;
        color: #4E4E4E;
        font-weight: 400;
        text-align: right;
        margin-bottom: 0;
        cursor: default;
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: flex-end;
        @media (max-width:768px) {
            text-align: center;    
           }
    }
}
.forgotsection{
    min-height: 403px;
}
.error_wraper{
    position: absolute;
    margin-top: 36px;
    background: rgb(248 232 234);
    width: 448px;
    padding: 14px 12px;
    border-radius:6px; 
    font-size: 16px;
    color: #27282B;
    font-weight: 400;
    line-height: 24px;
    z-index: 1;
    @media (max-width:1199px) {
        width: 300px;
    }
    img{
        margin-right: 15px;
    }
}
.emailsent-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    img{
        margin-bottom: 15px !important;
    }    
    h2{
        font-size: 24px;
        color: #323452;
        font-weight: 700;
        margin-bottom: 15px;
    }
    p{
        font-size: 16px;
        color: #323452;
        font-weight: 400;
        line-height: 23px;
        margin-bottom: 15px;
        @media (max-width:768px) {
            text-align: center;
        }
    }
    span{
        font-size: 14px;
        color: #323452;
        font-weight: 700;
        line-height: 23px;
    }
    a{
        background: #086dab;
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 15px;
        padding: 10px;
        width: 100%;
        text-align: center;
        text-decoration: none;
    }
}
.forgotError .MuiFormControl-root{
    margin: 0 0 15px 0 !important;
}
.resetPassword .btnSubmit{
    margin-top: 15px;
}

.resetPassword{
    padding: 15px 48px;
    .password-strength{
        p{
            margin-bottom: 0;
        }
    }
}


.dialog-heading {
    width: 600px;
    height: 78px;
    padding: 24px 24px 24px 32px;
    gap: 16px;
    display: flex;
    p {
        width: 496px;
        height: 30px;
        font-family: Barlow Semi Condensed;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: -0.025em;
        text-align: left;
    }
}
.backup-content {
    width: 100% !important;
    height: 285px !important;
    padding: 10px 35px 10px 35px !important;
}
.nxt-btn {
    margin-right: 30px !important;
    background-color: #036BAD !important;
    text-transform: none !important;
    font-family: Barlow Semi Condensed !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 28px !important;
    text-align: center !important;
}
.needasst_wrapper{
    height: calc(100% - 113px);
    @media (max-width: 1199px) {
        height: calc(100% - 145px);
    }
    .needAsst_header{
        background: #036BAD;
        border-bottom: 2px solid #CD3F00;
        padding: 16px;
        .logoImg{
            height: 32px;
            background-size: contain;
        }
    }
    .middleContainer{
        .left-section{
            padding-left: 50px;
            @media (max-width: 768px) {
                display: block !important;
            }
            h1{
                font-weight: 700;
                font-size: 28px;
                color: #086DAB;
                margin-bottom: 20px;
            }
            .child_wrapper{
                margin-bottom: 15px;
                h3{
                    font-weight: 600;
                    font-size: 26px;
                    color: #191919;
                    margin-bottom: 0px;
                }
                a{
                    font-weight: 400;
                    font-size: 18px;
                    text-decoration: none;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.assst_footer{
    height: auto;
    padding: 40px 200px 40px 200px;
    @media (max-width: 1199px){
        padding: 40px 0px 40px 0px;
    }
}