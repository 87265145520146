.camera_feed_floor{
    margin: 50px 20px 50px 20px !important;
    justify-content: flex-start !important;
    max-width: 205px;
    @media (max-width: 1198px) {
        margin: 16px 20px 16px 20px !important;
    }
    .floorLabel{
        font-weight: 700;
        font-size: 16px;
        color: #344054;
    }
    .css-5xhwnm{
        max-width: 250px !important;
    }
}
.video-container{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .videoPlaer_wrapper{
        max-width: 47%;
        width: 100%;
        margin: 0 24px;
        @media (max-width: 1198px) {
            max-width: 305px;
            margin: 0 7px;
        }
        @media (max-width: 768px) {
            max-width: 100%;
            margin: 0 16px;
        }
        .video-player{
            width:100%;
            height: 300px;
            @media (max-width: 768px) {
                height: 200px;
            }
        }
    }
    .camera_details{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #4E4E4E;
    }
}
.vjs-control-bar{
    direction: rtl
}
.video-js .vjs-picture-in-picture-control {
    display: none !important;
}
.cameraFeed_head{
    @media (max-width: 768px) {
        padding: 60px 24px 8px 24px !important;
    }
}