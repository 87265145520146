.userMangement_wrapper {
    width: 100%;

    .main_heading {
        border-bottom: 1px solid rgb(230, 236, 245);
        padding: 8px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
            color: rgba(3, 107, 173, 1);
            font-weight: 700;
            font-size: 32px;
            line-height: 38.4px;
        }
    }

    .AddUser {
        margin: 50px 19px 24px 19px;
        display: flex;
        justify-content: flex-end;

        .floor-dropdown {
            .MuiFormLabel-root {
                color: rgba(52, 64, 84, 1);
                font-weight: 700;
                font-size: 16px;
                line-height: 21px;
                font-family: "Barlow Semi Condensed", sans-serif !important;
                top: -18px;
            }
        }

        .MuiInputBase-root {
            // padding: 4px 12px;

            &::before {
                border: 1px solid rgba(152, 162, 179, 1);
                height: 42px;
                border-radius: 6px;
            }

            &::after {
                display: none;
            }
        }

        .MuiSvgIcon-root {
            fill: rgba(71, 84, 103, 1);
            top: 0;

        }

        .MuiNativeSelect-select {
            padding-left: 12px;
        }

        .css-r5in8e-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
            border: 1px solid rgba(152, 162, 179, 1);
        }

        button.addDevice {
            background: #036bad;
            color: #fff;
            padding: 6px 14px;
            border-radius: 5px;
            cursor: pointer;
            border: 2px solid transparent;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
        }
    }

    .inner_Wrapper {
        margin: 19px 24px;
        border: 1px solid rgb(230, 236, 245);
        border-radius: 6px;

        .floorMangementTable_wrapper {
            .MuiDataGrid-columnHeaderTitle {
                font-weight: 700;
                font-size: 16px;
                line-height: 21px;
                color: #1D2939;
                padding: 18px 0px;
            }

            .MuiDataGrid-cell {
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
                color: #27282B;
                padding: 18px 10px;
            }

            .MuiDataGrid-columnSeparator {
                display: none;
            }
        }
    }
}

// Modal
.addFlorModal {
    .Modal_header {
        display: flex;
        justify-content: space-between;

        h1 {
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 0;
            padding: 24px 0;
        }

        .close_button {
            border: none;
            background: transparent;

        }
    }

    .Modal_middle {
        display: flex;

        .inputdrapper {
            width: 50%;

        }

        .manageUser_details {
            span {
                padding-bottom: 38px;
                font-weight: 400;
                font-size: 17px;
                line-height: 25px;

                a {
                    background: none;
                    color: rgba(0, 169, 145, 1);
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 25px;
                    padding: 0;
                    border: none;
                    border-radius: 0;

                    &:hover {
                        border-bottom: 1px solid rgba(0, 169, 145, 1);
                    }

                }
            }
        }
    }
}

.inputdrapper label {
    font-size: 16px;
    display: block;
    margin-bottom: 5px;
    color: #4E4E4E;
    font-weight: 400;
    position: relative;
    font-family: "Barlow Semi Condensed", sans-serif !important;
}

.inputdrapper .MuiFormControl-root {
    width: 100% !important;
    margin: 0 0 8px 0 !important;

    input {
        border-radius: 4px;
        font-size: 14px;
        font-family: "Barlow Semi Condensed", sans-serif !important;
        padding: 12px 12px !important;
    }
}

.inputdrapper legend {
    display: none;
}

.addDeviceMiddle.AddFloorModal {
    .field_addDevice {
        display: flex;
        flex-wrap: wrap;

        .innerFields {
            max-width: 250px !important;
            width: 100% !important;
            margin: 0 7px !important;

            label {
                color: rgba(39, 40, 43, 1);
                font-weight: 700;
                line-height: 30px;
                font-family: "Barlow Semi Condensed", sans-serif !important;
            }

            .MuiOutlinedInput-notchedOutline {
                border: 1px solid rgba(78, 78, 78, 1);
            }

            label+.css-r5in8e-MuiInputBase-root-MuiInput-root {
                margin-top: 4px;
            }

            .MuiInputBase-root {
                // padding: 4px 12px;

                .MuiNativeSelect-select {
                    padding-bottom: 14px;
                    padding-left: 10px;
                }

                &::before {
                    border: 1px solid rgba(78, 78, 78, 1);
                    height: 50px;
                    border-radius: 6px;
                }

                &::after {
                    display: none;
                }
            }
        }
    }

}

.adddeviceFooter {
    // margin-top: 80px;
    margin-top: 32px;
    margin-bottom: 32px;

    .MuiBox-root {
        justify-content: flex-end;

        button {
            margin: 0 8px;
            font-family: "Barlow Semi Condensed", sans-serif !important;

        }

        .saveBtn {
            background: rgba(8, 109, 171, 1);
            font-weight: 700;
            font-size: 18px;
            border: none;
        }

        .cancelBtn {
            color: rgba(8, 109, 171, 1);
            font-weight: 700;
            font-size: 18px;
            border: none;
        }

        .loader-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
            /* Ensure it's above the dialog box */
        }
    }
}

.innerFields {
    .MuiInputLabel-root {
        transform: none !important; // Prevent label from transforming on focus
    }
}

.delte_modal {
    z-index: 9999 !important;

    .MuiBox-root {
        padding: 0 32px 0 32px;
        width: 500px;

        .Modal_header {
            height: 78px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            h1 {
                font-weight: 500;
                font-size: 24px;
                line-height: 30px;
                color: rgba(39, 40, 43, 1);
            }
        }

        .Modal_middle {
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            p {
                font-weight: 400;
                font-size: 18px;
                color: rgba(52, 64, 84, 1);
            }
        }

        .Modal_footer {


            .MuiBox-root {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 100px;
            }

            button {
                margin: 0 8px;
                font-family: "Barlow Semi Condensed", sans-serif !important;

            }

            .saveBtn {
                background: rgba(8, 109, 171, 1);
                font-weight: 700;
                font-size: 18px;
                border: none;
            }

            .cancelBtn {
                color: rgba(8, 109, 171, 1);
                font-weight: 700;
                font-size: 18px;
                border: none;
            }
        }
    }
}

.customerror {
    margin-top: -5px !important;
    color: #d32f2f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-bottom: 0;
    margin-left: 14px;
    margin-right: 14px;
}

.css-wq8qsl-MuiFormHelperText-root {
    color: #d32f2f !important;
}

.attachmentField {
    .attachmentBtn {
        background: transparent;
        box-shadow: none;
        padding: 0;
        min-width: 20px;

        &:hover {
            background: transparent;
            box-shadow: none;
            padding: 0;
            min-width: 20px;
        }
    }
}

.blurPrintLink {
    &:focus {
        text-decoration: none;
    }
}

.addDeviceAddFloor {
    margin: 25px 19px 4px 19px !important;
    border-bottom: 1px solid #E6ECF5 !important;
    align-items: flex-end;
    padding-bottom: 4px;
    .addDevice {
        margin-bottom: 12px;
    }
}

.addDeviceInnerWrppar {
    border: none !important;
    margin: 0px 24px 19px 24px !important;
    height: 75%;

    @media (min-width: 769px) and (max-width:1198px) {
        height: -webkit-fill-available;
    }

    .inner_addDevicewrapper {
        // background: transparent url('../../assets/images/floorOneMap.png') no-repeat;
        // background-size: 100% 100%;
        position: relative;

        img.main_bg_addv {
            width: 100%;
            height: 100%;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
            pointer-events: none;
        }

        &.active,
        &.inactive {
            transition: width 0.3s ease;
            /* Adjust duration as needed */
        }

        &.active {
            width: 70%;
        }

        &.inactive {
            width: 100%;
        }
    }

    .inner_sideBar {
        margin-left: 45px;
        border-left: 1px solid #E3E4E6;
        padding-top: 60px;
        position: relative;
        z-index: 10;
        background: #fff;
        border-left: 1px solid #e3e4e6;
        width: 30%;

        @media (max-width: 768px) {
            position: absolute;
            margin: 0px;
            padding: 0px;
            //width: 500px !important;
            right: 15px;
            top: 10px;

            .MuiBox-root {
                overflow: auto;
                width: 300px;
                right: 0px;
                position: relative;
            }

            .MuiDataGrid-root {
                width: 500px;
            }
        }

        @media (min-width: 769px) and (max-width:1198px) {
            position: absolute;
            margin: 0px;
            padding: 0px;
            //width: 500px !important;
            right: 15px;

            .MuiBox-root {
                overflow: auto;
                width: 315px;
                right: 0px;
                position: relative;
            }

            .MuiDataGrid-root {
                width: 500px;
            }
        }


        .MuiDataGrid-columnHeaderTitle {
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
            color: #1D2939;

            // @media (max-width: 768px) {
            //     //font-size: 14px;
            //     text-wrap: wrap;
            // }
        }

        .sidbarFields {
            font-weight: 400;
            font-size: 16px;
            color: #1D2939;
        }

        &.active,
        &.inactive {
            transition: width 0.3s ease;

            /* Adjust duration as needed */
            .innerContent,
            .undoRdoBtn {
                transition: opacity 0.3s ease;
                /* Fade in/out for inner content */
            }

            .excpBtn {
                transition: transform 0.3s ease;
                /* Rotate smoothly */
            }
        }

        &.active {
            width: 30%;

            @media(max-width: 768px) {
                display: flex;
                justify-content: end;
                right: 2px;
                width: 300px;
            }

            @media (min-width: 769px) and (max-width:1198px) {
                padding: 0px;
                width: auto;
            }

            .MuiBox-root {
                box-shadow: 0px 4px 4px 0px #00000040;
                border: 1px solid rgba(224, 224, 224, 1);
            }

            .innerContent {
                display: block;
            }

            .undoRdoBtn {
                display: flex;
            }

            .excpBtn {
                transform: rotate(180deg) !important;
            }
        }

        &.inactive {
            width: 0%;

            @media (min-width: 769px) and (max-width:1198px) {
                width: 2%;
            }

            .MuiBox-root {
                box-shadow: none;
            }

            .innerContent {
                display: none;
            }

            .undoRdoBtn {
                display: none;
            }

            .excpBtn {
                transform: rotate(0deg);
            }
        }

        .MuiBox-root {

            height: 450px !important;

            .MuiDataGrid-columnSeparator {
                display: none;
            }
        }

        .MuiDataGrid-columnHeader:nth-child(2) {
            border-right: 1px solid rgba(224, 224, 224, 1);
        }

        .MuiDataGrid-row {
            .MuiDataGrid-cell:nth-child(2) {
                border-top: none !important;
                border-right: 1px solid rgba(224, 224, 224, 1);
            }

            .MuiDataGrid-cell:nth-child(5) {
                border-top-right-radius: 10px;
            }
        }

        // .MuiDataGrid-filler{
        //     display: none !important;
        // }
        .excpBtn {
            background: transparent url('../../assets/images/ex_cl_btn.svg') no-repeat;
            width: 30px;
            height: 30px;
            position: relative;
            border: none;
            top: -10px;
            left: -14px;

            @media (max-width: 768px) {
                top: -2px;
                position: absolute;
            }

            @media (min-width: 769px) and (max-width: 1198px) {
                top: 10px;
            }
        }

        .table-overlay {
            background-color: #fff !important;

            @media (max-width: 768px) {
                position: absolute !important;
                margin-top: 30px;
            }

            @media (min-width: 769px) and (max-width: 1198px) {
                margin-top: 20px;
                width: -webkit-fill-available;
            }
        }
    }

}

.MuiDataGrid-menuList .MuiButtonBase-root:last-child {
    display: none !important;
}

@media (max-width: 768px) {
    .userMangement_wrapper {
        display: flex;
        flex-direction: column;

        .AddUser {
            order: 0;
            margin: 10px 19px 16px 19px !important;
        }

        .main_heading {
            order: 2;
        }

        .inner_Wrapper {
            order: 2;
        }

    }

    .addFlorModal {
        .MuiBox-root {
            max-width: 350px;
            width: 100%;
        }

    }

    .addDeviceMiddle.AddFloorModal .field_addDevice .innerFields {
        max-width: 100% !important;
        margin-bottom: 24px !important;
    }

    .addFlorModal .Modal_header .close_button {
        padding: 0 0;
    }

    .addFlorModal .adddeviceFooter {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .delte_modal .MuiBox-root {
        width: 370px !important;
    }

    .floorMangementTable_wrapper {
        overflow-x: auto;

        &>.MuiBox-root {
            width: 650px;
        }
    }
}