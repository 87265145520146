.SideBar_wrapper{
    // width: 7.2%;
    // height: 100%;
    // padding: 41px 14px 0 14px;
    display: flex;
    justify-content: center;
    background-color: rgba(250, 251, 251, 1);
    border-right: 1px solid rgba(230, 236, 245, 1);
    .sideBar_excp_btn{
        display: none;
        @media (max-width: 768px) {
            display: block;
            padding: 0;
            margin: 6px 0 0 12px;
            position: absolute;
            background: transparent;
            z-index: 99;
            transition: left 0.3s ease-in-out;
        }
        &.off{
            left: 12px; 
        }
        &.on{
            left: 55px;
        }
    }

    ul{
        display: flex;
        list-style-type: none;
        align-items: center;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        li{
            a{
                text-decoration: none;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                width: 90px;
                height: 77px;
                border-left: 2px solid  transparent;
                color: rgba(78, 78, 78, 1);
                text-align: center;
                .browse{
                    background: url('../../assets/images/browse.svg');
                    height: 24px;
                    width: 24px;
                }
                .floor{
                    background: url('../../assets/images/floor.svg');
                    height: 24px;
                    width: 24px;
                }
                .floormangement{
                    background: url('../../assets/images/floorMangement.svg');
                    height: 32px;
                    width: 32px;
                }
                .proj{
                    background: url('../../assets/images/proj.svg');
                    height: 24px;
                    width: 24px;
                }
                .browse_activity{
                    background: url('../../assets/images/browse_activity.svg');
                    height: 24px;
                    width: 24px;
                }
                .monitoring{
                    background: url('../../assets/images/monitoring.svg');
                    height: 24px;
                    width: 24px;
                }
                .help{
                    background: url('../../assets/images/help.svg');
                    height: 24px;
                    width: 24px;
                }
                .logout{
                    background: url('../../assets/images/logout.svg');
                    height: 24px;
                    width: 24px;
                }
                
                // &:hover{
                //      border-left: 2px solid  rgba(205, 63, 0, 1);
                //      background: rgba(8, 109, 171, 1);
                //      color:#fff;
                //     .browse{
                //         background: url('../../assets/images/browse_hover.svg');
                //     }
                //     .floor{
                //         background: url('../../assets/images/floor_hover.svg');
                //     }
                //     .proj{
                //         background: url('../../assets/images/proj_hover.svg');
                //     }
                //     .browse_activity{
                //         background: url('../../assets/images/browse_activity_hover.svg');
                //     }
                //     .monitoring{
                //         background: url('../../assets/images/monitoring_hover.svg');
                //     }
                //     .help{
                //         background: url('../../assets/images/help_hover.svg');
                //     }
                //     .logout{
                //         background: url('../../assets/images/logout_hover.svg');
                //     }
                // }
                &.active{
                    border-left: 2px solid  rgba(205, 63, 0, 1);
                    background: rgba(8, 109, 171, 1);
                    color:#fff;
                    .browse{
                        background: url('../../assets/images/browse_hover.svg');
                    }
                    .floor{
                        background: url('../../assets/images/floor_hover.svg');
                    }
                    .proj{
                        background: url('../../assets/images/proj_hover.svg');
                    }
                    .browse_activity{
                        background: url('../../assets/images/browse_activity_hover.svg');
                    }
                    .floormangement{
                        background: url('../../assets/images/floorMangement_hover.svg');
                    }
                    .monitoring{
                        background: url('../../assets/images/monitoring_hover.svg');
                    }
                    .help{
                        background: url('../../assets/images/help_hover.svg');
                    }
                }
                img{
                    height: 24px;
                    width: 24px;
                    margin-bottom: 4px;
                }
                span{
                    font-weight:400;
                    font-Size:14px;
                    line-height:21px;
                    cursor: pointer;


                }
            }
        }
    }
    @media (max-width: 768px) {
        .sideBar_nav_listing{
            position: absolute;
            left: -90px;
            transition: left 0.3s ease-in-out;
            background-color: rgb(250, 251, 251);
            border-right: 1px solid rgb(230, 236, 245);
            box-shadow: 0px 4px 4px 0px #00000040;
            &.show{
                left: 0;
                z-index: 9;
                
            }
        }
    }
}