.em-wrapper {
    width: 541px;
    height: 241px;
    padding: 16px;
    gap: 24px;
    border-radius: 6px;
    border: 1px solid #e6ecf5;

    @media (max-width: 768px) {
        max-width: 100%;
        margin: 15px;
    }

    .heading {
        width: 509px;
        height: 23px;
        font-family: Barlow Semi Condensed;
        font-size: 24px;
        font-weight: 600;
        line-height: 28.8px;
        text-align: left;
        color: #323452;

        @media (max-width: 768px) {
            width: auto;
        }

        .settings {
            display: flex;
            gap: 70%;
            margin-top: 15px;
            border-bottom: 1px solid #F3F3F3;
            justify-content: space-between;

            @media (max-width: 768px) {
                gap: 45%;
            }

            p {
                font-family: Barlow Semi Condensed;
                font-size: 16px;
                font-weight: 400;
                top: 6px;
                position: relative;
                text-align: center;
            }

        }

        .settings1 {
            display: flex;
            gap: 58%;
            margin-top: 15px;
            border-bottom: 1px solid #F3F3F3;
            justify-content: space-between;

            @media (max-width: 768px) {
                gap: inherit;
            }

            p {
                font-family: Barlow Semi Condensed;
                font-size: 16px;
                font-weight: 400;
                top: 6px;
                position: relative;
                text-align: center;
            }
        }

    }
}