.session_modal{
  z-index: 9999 !important;
  .MuiBox-root{
    padding: 32px;
    .Modal_header{
      h1{
        font-weight: 500;
        font-size: 24px;
        color: #323452;
      }
    }
    .Modal_middle{
      p{
        font-weight: 400;
        font-size: 16px;
        color: #323452;
      }
    }
    .Modal_footer{
        button{
          font-weight: 500;
          font-size: 16px;
          color: #fff;
          border:none;
          background: #036bad;
          border-radius: 5px;
          padding: 6px 14px;
          margin: 0 7px;
        }
    }
  }
}