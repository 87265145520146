.profileWrapper {
  width: 100%;

  .main_heading {
    border-bottom: 1px solid rgb(230, 236, 245);
    padding: 8px 24px;

    @media (max-width: 768px) {
      margin-top: 50px;
  }

    h1 {
      color: rgba(3, 107, 173, 1);
      font-weight: 700;
      font-size: 32px;
      line-height: 38.4px;
      margin-bottom: 0;
    }
  }

  .menu_bar {
    border-bottom: 1px solid rgb(230, 236, 245);
    padding: 0px 24px;

    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;
      // width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        width: auto;
        border: none;
        background: transparent;
        color: rgba(78, 78, 78, 1);
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        padding: 10px 10px;

        &.active {
          border-bottom: 4px solid rgba(3, 107, 173, 1);
          font-weight: 600;
        }
      }
    }
  }

  .userPassdDetail_wrapper {
    min-width: auto;
    width: 100%;
    margin-top: 54px !important;
    display: flex;
    justify-content: center;
    //position: relative;

    .name_wrapper {
      width: 220px;
      height: 290px;
      border: 1px solid rgba(230, 236, 245, 1);
      padding: 30px 16px;
      border-radius: 6px;
      margin-right: 12px;

      .edit_name {
        color: #323452;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
      }

      .edit_profilePic {
        margin: 15px 0 25px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 145px;
          width: 145px;
          border-radius: 50%;
        }
      }

      .name {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-right: 3px;
        }

        button {
          color: rgba(8, 109, 171, 1);
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          border: none;
          background: transparent;
        }
      }
    }

    .update_details {
      width: 600px;
      height: 400px;
      border: 1px solid rgba(230, 236, 245, 1);
      padding: 30px 16px;
      border-radius: 6px;
      margin-right: 12px;

      label {
        font-size: 16px !important;
        font-weight: 400 !important;
        color: rgba(78, 78, 78, 1) !important;
        line-height: 24px !important;
      }
    }

    .update_wrapper {
      width: 448px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgb(230, 236, 245);
      border-radius: 6px;
      padding: 20px 20px;
      margin: 0px 6px;

      .profile-form {
        width: 100%;

        &.dropdownWrapper {
          label {
            margin-bottom: 0 !important;
            color: rgba(78, 78, 78, 1) !important;
          }

          .MuiInputBase-root {
            margin-top: 8px !important;

            &:hover {
              &::before {
                border-bottom: none;
              }
            }

            &::after {
              display: none;
            }
          }

          .MuiNativeSelect-select {
            border: 1px solid rgba(118, 119, 139, 1);
            color: rgba(50, 52, 82, 1) !important;
            padding: 10px 10px !important;
          }

          .MuiSvgIcon-root {
            fill: rgba(50, 52, 82, 1) !important;
          }
        }
      }

      .profile-form h2 {
        font-size: 24px;
        margin-bottom: 15px;
        text-align: left;
        color: #4e4e4e;
        font-weight: 700;
      }

      .profile-form label {
        font-size: 16px;
        display: block;
        margin-bottom: 10px;
        color: #323452;
        font-weight: 400;
        position: relative;
        font-family: "Barlow Semi Condensed", sans-serif !important;
      }

      .profile-form .MuiFormControl-root {
        width: 100% !important;
        margin: 0 0 8px 0 !important;

        input {
          border-radius: 4px;
          font-size: 14px;
          font-family: "Barlow Semi Condensed", sans-serif !important;
          padding: 12px 12px !important;
        }
      }

      .profile-form legend {
        display: none;
      }

      .profile_wrapperbtn {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;

        button.btnSubmit {
          border: none;
          background: transparent;
          font-size: 16px;
          color: #323452;
          font-weight: 400;
          line-height: 20px;
          padding: 10px 20px;
        }

        button.cancel {
          border: 1px solid #323452;
          border-radius: 5px;
          margin-right: 10px;
        }

        button.save {
          border: 1px solid #003e9c;
          border-radius: 5px;
          background: #003e9c;
          color: #fff;
        }
      }
    }

    @media (max-width: 768px) {

      .userPassdDetail_wrapper {
        width: auto;
        height: 500px;
      }

      .notifications_wrapper {
        width: auto;
        padding: 20px;
        margin: 0 20px;
        border: 1px solid rgba(230, 236, 245, 1);
        border-radius: 6px;
        box-sizing: border-box;
        height: auto;
      }

      .notifications_wrapper .heading {
        width: 100%;
      }

    }
  }
}

.error_wraperProfile {
  position: absolute;
  margin-top: 0px;
  background: rgb(248 232 234);
  width: 448px;
  padding: 14px 12px;
  border-radius: 6px;
  font-size: 16px;
  color: #27282b;
  font-weight: 400;
  line-height: 24px;
  top: -70px;

  img {
    margin-right: 15px;
  }
}

.success_wraperProfile {
  position: absolute;
  margin-top: 0px;
  background: #edfaea;
  width: 448px;
  padding: 14px 12px;
  border-radius: 6px;
  font-size: 16px;
  color: #27282b;
  font-weight: 400;
  line-height: 24px;
  top: -70px;

  img {
    margin-right: 15px;
  }
}

.password-strength {
  .valid {
    color: #4acf35;
  }
}

.sucssess_Box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.sucssess_Box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 448px;
  margin-bottom: 30px;

  img {
    margin-bottom: 15px !important;
  }

  h2 {
    font-size: 24px;
    color: #323452;
    font-weight: 700;
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
    color: #323452;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 15px;
  }

  span {
    font-size: 14px;
    color: #323452;
    font-weight: 700;
    line-height: 23px;
  }

  .btnLogin {
    color: #00a991;
    font-size: 17px;
    font-weight: 400;
    background: transparent;
    border: none;
  }
}

.uploadMediaContainer {
  max-width: 536px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 28px 24px;
  min-height: 200px;
  position: relative;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px;
  }

  .uploadFileMiddle {
    max-width: 536px;
    width: 100%;
    border: 1px dashed rgba(91, 93, 117, 1);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 28px 24px;
    min-height: 145px;
    position: relative;
    display: flex;
    margin-bottom: 0px;
    justify-content: flex-start;

    @media (max-width: 768px) {
      padding: 28px 22px;
    }

    .reactEasyCrop_Container {
      height: 145px;
    }

    .inst {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 15px;
      margin-bottom: 0px;
    }

    .type {
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 0px;
    }
  }
}

.profileModal {
  .MuiBox-root {
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    padding-bottom: 0;
    padding-top: 0;

    .MuiFormControl-root {
      width: 32px !important;
      margin: 0 0 0px 0 !important;

      input {
        display: none;
      }

      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  }

  .Modal_header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 0;
      padding: 24px 0;
    }

    .close_button {
      border: none;
      background: transparent;
    }
  }

  .Modal_footer {
    width: 100%;

    .MuiBox-root {
      flex-direction: row;
      height: 100px;
      justify-content: flex-end;

      button {
        margin-left: 15px;
      }
    }
  }

  .Modal_middle {
    display: flex;

    .inputdrapper {
      width: 50%;
    }
  }
}

.reactEasyCrop_Image {
  max-width: 100px;
  max-height: 100px;
}

.errorMessage {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(205, 63, 0, 1);
}

.update_pass_mnWrapper {
  display: flex;
  flex-direction: column;

  .updatePassword_myacc {
    display: flex;

    .security_check_wrapper {
      align-items: flex-start !important;

      .securityChecks {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 7px;
        border-bottom: 1px solid rgba(243, 243, 243, 1);

        .sc_heading {
          p {
            margin-bottom: 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: rgba(50, 52, 82, 1);
          }

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: rgba(116, 116, 116, 1);
          }
        }
      }
    }
  }

  .activityLog_wrapper {
    border: 1px solid rgb(230, 236, 245);
    padding: 16px;
    margin: 24px 6px 24px 6px;
    display: flex;
    flex-direction: column;

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: rgba(50, 52, 82, 1);
    }

    .viewLog_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .viewLogs_details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        p {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: rgba(50, 52, 82, 1);
        }

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: rgba(116, 116, 116, 1);
        }

        a {
          text-decoration: none;
          color: rgba(8, 109, 171, 1);
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}

.reactEasyCrop_CropArea {
  border-radius: 50%;
}

.MuiSnackbar-root {
  &.success {
    .MuiPaper-root {
      background-color: #e5f2eb !important;
    }

    .MuiSnackbarContent-message {
      background: url("../../assets/images/check_circle_small.svg");
    }
  }

  &.error {
    .MuiPaper-root {
      background-color: #f8e8ea !important;
    }

    .MuiSnackbarContent-message {
      background: url("../../assets/images/errorSign.svg");
    }
  }

  .MuiPaper-root {
    color: #212831 !important;
    margin-top: 43px;

    .MuiSnackbarContent-message {
      background-repeat: no-repeat;
      background-size: 22px 22px;
      background-position: left center;
      padding: 8px 0 8px 37px;
    }
  }

  @media (max-width: 768px) {
    max-width: 230px;
    top: 23px !important;
    left: auto !important;
  }
}

.save-btn {
  text-transform: none !important;
  width: 68px;
  height: 40px;
  border-radius: 5px !important;
  font-family: 'Barlow Semi Condensed', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.notifications_wrapper {
  width: 541px;
  height: auto;
  padding: 16px;
  gap: 24px;
  border-radius: 6px;
  border: 1px solid #e6ecf5;

  .heading {
    width: 509px;
    height: 23px;
    font-family: Barlow Semi Condensed;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    text-align: left;
    margin-bottom: 20px;
  }

  .noti {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #e6ecf5;
    margin-bottom: 3%;
    margin-top: 12px;

    .sc_heading {
      width: 90%;

      p {
        margin-bottom: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 12px;
        color: rgba(50, 52, 82, 1);
      }

      span {
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        color: rgba(116, 116, 116, 1);
      }

      .sc_toggle {
        width: 44px;
        height: 24px;
        padding: 2px;
        border-radius: 9999px;
        background: #e5e7eb;
      
      }
    }

    .pin-wrapper{
      margin-bottom: 15px;

      label{
        font-family: Barlow Semi Condensed;
        font-size: 13px;
      }
    }

    .heading {
      width: 509px;
      height: 23px;
      font-family: Barlow Semi Condensed;
      font-size: 24px;
      font-weight: 600;
      line-height: 28.8px;
      text-align: left;
      color: #323452;
    }

    .save {
      text-transform: none !important;
      width: 68px;
      height: 40px;
      border-radius: 5px;
      background-color: #086DAB;
    }

    .dialog-font {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
    }

    .dialog-msg {
      font-family: Barlow Semi Condensed;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      text-align: center;
    }
  }

  //Moved to separate file EmergencySettings.css

  // .em-wrapper {
  //   width: 541px;
  //   height: 241px;
  //   padding: 16px;
  //   gap: 24px;
  //   border-radius: 6px;
  //   border: 1px solid #e6ecf5;

  //   @media (max-width: 768px) {
  //     max-width: 100%;
  //     margin: 15px;
  //   }

  //   .heading {
  //     width: 509px;
  //     height: 23px;
  //     font-family: Barlow Semi Condensed;
  //     font-size: 24px;
  //     font-weight: 600;
  //     line-height: 28.8px;
  //     text-align: left;
  //     color: #323452;

  //     @media (max-width: 768px) {
  //       width: auto;
  //     }

  //     .settings {
  //       display: flex;
  //       gap: 70%;
  //       margin-top: 15px;
  //       border-bottom: 1px solid #F3F3F3;
  //       justify-content: space-between;

  //       @media (max-width: 768px) {
  //         gap: 45%;
  //       }

  //       p {
  //         font-family: Barlow Semi Condensed;
  //         font-size: 16px;
  //         font-weight: 400;
  //         top: 6px;
  //         position: relative;
  //         text-align: center;
  //       }
  //     }

  //     .settings1 {
  //       display: flex;
  //       gap: 58%;
  //       margin-top: 15px;
  //       border-bottom: 1px solid #F3F3F3;
  //       justify-content: space-between;

  //       @media (max-width: 768px) {
  //         gap: inherit;
  //       }

  //       p {
  //         font-family: Barlow Semi Condensed;
  //         font-size: 16px;
  //         font-weight: 400;
  //         top: 6px;
  //         position: relative;
  //         text-align: center;
  //       }
  //     }
  //   }
  // }

    //Moved to separate file MediaSettings.css
    
  // .media_control_wrapper {
  //   width: 100%;

  //   .media_wrapper {
  //     padding: 16px;
  //     border-radius: 6px;
  //     border: 1px solid #e6ecf5;
  //     position: relative;
  //     margin: 0 19px;

  //     // left: 45px;
  //     .heading {
  //       @media (max-width: 768px) {
  //         margin-bottom: 20px;
  //       }

  //       width: 100%;
  //       height: 23px;
  //       font-family: Barlow Semi Condensed;
  //       font-size: 24px;
  //       font-weight: 600;
  //       line-height: 28.8px;
  //       text-align: left;
  //       color: #323452;

  //     }

  //     .dropdown-warpper {
  //       width: 100%;
  //       max-width: 576px;
  //       border: 0px 0px 1px 0px;

  //       .sub-heading {
  //         width: 100%;
  //         height: 21px;
  //         font-family: Barlow Semi Condensed;
  //         font-size: 18px;
  //         font-weight: 700;
  //         line-height: 21px;
  //         text-align: left;
  //         color: #1d2939;
  //         position: relative;
  //         top: -30px;

  //         drp-text {
  //           font-family: Barlow Semi Condensed;
  //           font-size: 16px;
  //           font-weight: 400;
  //           line-height: 24px;
  //           letter-spacing: 0.0275em;
  //           text-align: left;
  //         }
  //       }
  //     }

  //     button.btnSubmit {
  //       border: none;
  //       background: transparent;
  //       font-size: 16px;
  //       color: #323452;
  //       font-weight: 400;
  //       line-height: 20px;
  //       padding: 10px 20px;
  //       margin-left: 50%;
  //     }

  //     button.save {
  //       background: #036bad;
  //       color: #fff;
  //       width: 68px;
  //       height: 40px;
  //       padding: 10px 19px 10px 19px;
  //       border-radius: 5px;
  //       font-size: 16px;
  //       font-weight: 400;
  //       font-family: "Barlow Semi Condensed", sans-serif !important;
  //       text-transform: none;
  //     }

  //     .btn-wrapper {
  //       width: 100%;
  //       display: flex;
  //       justify-content: flex-end;
  //     }

  //     button:disabled {
  //       background-color: #ccc;
  //       cursor: not-allowed;
  //     }

  //     .dialog-font {
  //       font-family: Poppins;
  //       font-size: 24px;
  //       font-weight: 700;
  //       line-height: 36px;
  //       text-align: center;
  //     }
  //   }
  // }

  .audioMiddle {
    flex-direction: column;

    .audioTitle label {
      color: rgb(39, 40, 43);
      font-weight: 700;
      line-height: 30px;
      font-family: "Barlow Semi Condensed", sans-serif !important;
    }

    .loopRegions {
      display: flex;
      width: 100%;
      margin: 0 0 15px 0;
    }

    label {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 16px;
      color: rgb(50, 52, 82);
    }

    .custom-file-upload {
      background: #E7E7E7;
      font-weight: 400;
      font-size: 16px;
      color: rgb(50, 52, 82);
      width: 110px;
      padding: 8px;
      text-align: center;
      border-radius: 5px;
      color: #27282B;
      margin-bottom: 12px;
      cursor: pointer;
    }

    .playbtn_box {
      margin-bottom: 15px;

      button {
        padding: 0;
        background: transparent;

        img {
          height: 22px;
        }
      }
    }

    .recording-file-upload {
      button {
        background: #036BAD;
        font-weight: 400;
        font-size: 16px;
        color: #fff !important;
        width: 118px;
        padding: 8px;
        text-align: center;
        border-radius: 5px;
        color: #27282B;
        cursor: pointer;
        margin-right: 16px;
      }

      .recordingtext {
        color: #27282B;
        font-weight: 400;
        font-size: 16px;
        margin-left: 5px;
        animation: fadeInOut 2s infinite;
      }

      @keyframes fadeInOut {
        0% {
          opacity: 0;
        }

        50% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }
    }
  }

  .audioErrorMessage {
    margin-bottom: 5px;
    color: rgba(205, 63, 0, 1);
  }
}

.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-modal {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 5px;
  width: 600px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Barlow Semi Condensed;

  @media (max-width: 768px) {
    width: auto;
  }

  .confirm-header {
    display: flex;
    gap: 15px;
    padding-bottom: 10px;
  }

  img {
    width: 32px;
    height: 32px;
    margin-bottom: 16px;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    text-align: left;
  }

  p {
    padding-bottom: 20px;
    color: #344054;
    text-align: left;
    font-family: Barlow Semi Condensed;
    font-size: 18px;
    font-weight: 400;
  }

  .drill-btn-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
      font-size: 16px;
      text-transform: none;
      border-radius: 8px;
      height: 40px;
    }

    .cancel-button {
      background-color: #ffffff;
      color: #086DAB;
      font-family: Barlow Semi Condensed;
      font-size: 18px;
      font-weight: 700;
    }

    .confirm-button {
      font-size: 18px;
      font-weight: 700;
      text-transform: none;
      background-color: #086DAB;
      border-radius: 6px;
      font-family: Barlow Semi Condensed;
      padding: 7px 16px;
      color: white;
    }

    .confirm-button:hover {
      background-color: #065A8A;
    }

    .cancel-button:hover {
      background-color: #f0f0f0;
    }
  }
}
.drill_check_wrapper{
  width: 540px  !important;
  .drillChecks{
    padding: 25px 0 !important;
  }
}

  .drillTogles{
    .Mui-disabled{
    opacity: 0.8;
  }
}

.drillSettingUpdated{
  top: 64px !important;
  .MuiPaper-root{
    margin-top: 20px !important;
  }
}