.media_control_wrapper {
  width: 100%;

  .media_wrapper {
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #e6ecf5;
    position: relative;
    margin: 0 19px;

    .heading {
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }

      width: 100%;
      height: 23px;
      font-family: Barlow Semi Condensed;
      font-size: 24px;
      font-weight: 600;
      line-height: 28.8px;
      text-align: left;
      color: #323452;
    }

    .dropdown-wrapper {
      width: 100%;
      border: 0px 0px 1px 0px;

      @media (max-width: 1198px) {
        font-size: 10px;
      }

      .MuiBox-root {
        display: flex;
        gap: 100px;

        @media (max-width: 1198px) {
          gap: 15px;
        }
      }

      .MuiFormControl-root {
        @media (max-width: 1198px) {
          margin-top: 6% !important;
        }
      }
      .MuiNativeSelect-select {
        color: rgb(50, 52, 82) !important;
        padding: 10px 10px;

        // @media (max-width: 768px) {
        //     width: 85%;
        // }
      }

      .css-r5in8e-MuiInputBase-root-MuiInput-root:after {
        border-bottom: 0px;
      }

      .sub-heading {
        width: 100%;
        height: 21px;
        font-family: Barlow Semi Condensed;
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-align: left;
        color: #1d2939;
        position: relative;
        top: -30px;
      }
    }
    button.btnSubmit {
      border: none;
      background: transparent;
      font-size: 16px;
      color: #323452;
      font-weight: 400;
      line-height: 20px;
      padding: 10px 20px;
      margin-left: 50%;
    }

    button.save {
      background: #036bad;
      width: 68px;
      height: 40px;
      padding: 10px 19px 10px 19px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 600;
      font-family: "Barlow Semi Condensed", sans-serif !important;
      text-transform: none;
    }

    .btn-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
}

.audioMiddle {
  flex-direction: column;

  .audioTitle label {
    color: rgb(39, 40, 43);
    font-weight: 700;
    line-height: 30px;
    font-family: "Barlow Semi Condensed", sans-serif !important;
  }

  .loopRegions {
    display: flex;
    width: 100%;
    margin: 0 0 15px 0;
  }

  label {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    color: rgb(50, 52, 82);
  }

  .custom-file-upload {
    background: #e7e7e7;
    font-weight: 400;
    font-size: 16px;
    color: rgb(50, 52, 82);
    width: 110px;
    padding: 8px;
    text-align: center;
    border-radius: 5px;
    color: #27282b;
    margin-bottom: 12px;
    cursor: pointer;
  }

  .playbtn_box {
    margin-bottom: 15px;

    button {
      padding: 0;
      background: transparent;

      img {
        height: 22px;
      }
    }
  }

  .recording-file-upload {
    button {
      background: #036bad;
      font-weight: 400;
      font-size: 16px;
      color: #fff !important;
      width: 118px;
      padding: 8px;
      text-align: center;
      border-radius: 5px;
      color: #27282b;
      cursor: pointer;
      margin-right: 16px;
    }

    .recordingtext {
      color: #27282b;
      font-weight: 400;
      font-size: 16px;
      margin-left: 5px;
      animation: fadeInOut 2s infinite;
    }

    @keyframes fadeInOut {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
  }
}

.media_control_wrapper {
  width: 100%;

  .media_wrapper {
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #e6ecf5;
    position: relative;
    margin: 0 19px 20px;

    .heading {
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }

      width: 100%;
      height: 23px;
      font-family: Barlow Semi Condensed;
      font-size: 24px;
      font-weight: 600;
      line-height: 28.8px;
      text-align: left;
      color: #323452;
    }

    .dropdown-wrapper {
      width: 100%;
      border: 0px 0px 1px 0px;

      @media (max-width: 1198px) {
        font-size: 10px;
      }

      .MuiBox-root {
        display: flex;
        gap: 100px;

        @media (max-width: 1198px) {
          gap: 15px;
        }
      }

      .MuiFormControl-root {
        @media (max-width: 1198px) {
          margin-top: 9% !important;
        }
      }
      .MuiNativeSelect-select {
        color: rgb(50, 52, 82) !important;
        padding: 10px 10px;

        // @media (max-width: 768px) {
        //     width: 85%;
        // }
      }

      .css-r5in8e-MuiInputBase-root-MuiInput-root:after {
        border-bottom: 0px;
      }

      .sub-heading {
        width: 100%;
        height: 21px;
        font-family: Barlow Semi Condensed;
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-align: left;
        color: #1d2939;
        position: relative;
        top: -30px;
      }
    }
    button.btnSubmit {
      border: none;
      background: transparent;
      font-size: 16px;
      color: #323452;
      font-weight: 400;
      line-height: 20px;
      padding: 10px 20px;
      margin-left: 50%;
    }

    button.save {
      background: #036bad;
      width: 68px;
      height: 40px;
      padding: 10px 19px 10px 19px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 600;
      font-family: "Barlow Semi Condensed", sans-serif !important;
      text-transform: none;
    }

    .btn-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
}

.audioMiddle {
  flex-direction: column;

  .audioTitle label {
    color: rgb(39, 40, 43);
    font-weight: 700;
    line-height: 30px;
    font-family: "Barlow Semi Condensed", sans-serif !important;
  }

  .loopRegions {
    display: flex;
    width: 100%;
    margin: 0 0 15px 0;
  }

  label {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    color: rgb(50, 52, 82);
  }

  .custom-file-upload {
    background: #e7e7e7;
    font-weight: 400;
    font-size: 16px;
    color: rgb(50, 52, 82);
    width: 110px;
    padding: 8px;
    text-align: center;
    border-radius: 5px;
    color: #27282b;
    margin-bottom: 12px;
    cursor: pointer;
  }

  .playbtn_box {
    margin-bottom: 15px;

    button {
      padding: 0;
      background: transparent;

      img {
        height: 22px;
      }
    }
  }

  .recording-file-upload {
    button {
      background: #036bad;
      font-weight: 400;
      font-size: 16px;
      color: #fff !important;
      width: 118px;
      padding: 8px;
      text-align: center;
      border-radius: 5px;
      color: #27282b;
      cursor: pointer;
      margin-right: 16px;
    }

    .recordingtext {
      color: #27282b;
      font-weight: 400;
      font-size: 16px;
      margin-left: 5px;
      animation: fadeInOut 2s infinite;
    }

    @keyframes fadeInOut {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
  }
}

.media_wrapper2 {
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #e6ecf5;
  position: relative;
  margin: 0 19px;

  .heading {
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }

    width: 100%;
    height: 23px;
    font-family: Barlow Semi Condensed;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    text-align: left;
    color: #323452;
  }

  .sub-heading {
    width: 100%;
    height: 35px;
    font-family: Barlow Semi Condensed;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;
    color: #1d2939;
    position: relative;
  }

  .dropdown-wrapper {
    width: 100%;
    border: 0px 0px 1px 0px;
    display: flex;
    gap: 30px;

    @media (max-width: 1198px) {
      font-size: 10px;
    }

    .MuiBox-root {
      display: flex;
      gap: 100px;

      @media (max-width: 1198px) {
        gap: 15px;
      }
    }

    .MuiFormControl-root {
      @media (max-width: 1198px) {
        margin-top: 9% !important;
      }
    }
    .MuiNativeSelect-select {
      color: rgb(50, 52, 82) !important;
      padding: 10px 10px;

      // @media (max-width: 768px) {
      //     width: 85%;
      // }
    }

    .css-r5in8e-MuiInputBase-root-MuiInput-root:after {
      border-bottom: 0px;
    }
  }
  button.btnSubmit {
    border: none;
    background: transparent;
    font-size: 16px;
    color: #323452;
    font-weight: 400;
    line-height: 20px;
    padding: 10px 20px;
    margin-left: 50%;
  }

  button.save {
    background: #036bad;
    width: 68px;
    height: 40px;
    padding: 10px 19px 10px 19px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Barlow Semi Condensed", sans-serif !important;
    text-transform: none;
  }

  .btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.media-modify {
  display: flex;
  gap: 30px;
  padding-bottom: 20px;
  align-items: center;

  @media (max-width: 1198px) {
    overflow: auto;
  }

  .media-custom {
    display: flex;
    width: 100%;

    .custom-title {
      font-family: "Barlow Semi Condensed";
      font-size: 16px;
      font-weight: 400;
      text-align: center;
    }

      .switch-size {
        height: 24px;
        padding: 2px;

        @media (max-width: 1198px) {
            height: 20px;
          }
    }
  }

  .custom-text {
    width: 274px;
    height: 44px;
    display: flex;
    align-items: center;
  }
}

.error-btn-container {
  display: flex; /* Aligns error message and button in the same row */
  align-items: center; /* Vertically centers items */
  gap: 10px; /* Space between error message and button */
}

.customerror {
  color: red;
  font-family: Barlow Semi Condensed;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
}
