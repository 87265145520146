html{
  height: 100%;
}
.App {
  height: 100%;
}
/*

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* * {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}*/
body {
  -webkit-text-size-adjust: none;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
} 
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
#root{
  /* width: 100%;
  
  position: absolute; */
  height: 100%;
  position: relative;
}
.middle_container{
  /* max-height: 979px; */
  display: flex;
  height: calc( 100% - 64px);
}

.css-dwkzlu-MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .css-dwkzlu-MuiDataGrid-root .MuiDataGrid-cell:focus{
  outline:none !important;
}
.MuiTablePagination-displayedRows{
  margin-bottom: 0;
}