.userMangement_wrapper{
    width: 100%;

    .main_heading{
        border-bottom: 1px solid rgb(230, 236, 245);
        padding:8px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1{
            color:rgba(3, 107, 173, 1);
            font-weight: 700;
            font-size: 32px;
            line-height:38.4px;
        }
    }
    .AddUser{
        margin: 50px 19px 24px 19px;
        display: flex;
        justify-content: flex-end;
        .floor-dropdown{
            .MuiFormLabel-root{
                color: rgba(52, 64, 84, 1);
                font-weight: 700;
                font-size: 16px;
                line-height: 21px;
                font-family: "Barlow Semi Condensed", sans-serif !important;
                top: -10px;
            }
        }
        .MuiInputBase-root{
            // padding: 4px 12px;
          
            &::before{
                border: 1px solid rgba(152, 162, 179, 1);
                height: 42px;
                border-radius: 6px;
            }
            &::after{
             display: none;
            }
        }
        .MuiSvgIcon-root{
            fill:rgba(71, 84, 103, 1);
            top: 0;

        }
        .MuiNativeSelect-select{
            padding-left: 12px;
        }
        .css-r5in8e-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before{
            border: 1px solid rgba(152, 162, 179, 1);
        }
         button.addDevice{
            background: #036bad;
            color: #fff;
            padding: 6px 14px;
            border-radius: 5px;
            cursor: pointer;
            border: 2px solid transparent;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
        }
    }
    .inner_Wrapper{
        margin: 19px 24px;
        border: 1px solid rgb(230, 236, 245);
        border-radius: 6px;
        .floorMangementTable_wrapper{
            .MuiDataGrid-columnHeaderTitle{
                font-weight: 700;
                font-size: 16px;
                line-height: 21px;
                color: #1D2939;
                padding: 18px 0px;
            }
            .MuiDataGrid-cell{
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
                color: #27282B;
                padding: 18px 10px;
            }
            .MuiDataGrid-columnSeparator{
                display: none;
            }
        }
    }
}

// Modal
.addFlorModal{
    .Modal_header{
        display: flex;
        justify-content: space-between;
        h1{
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 0;
            padding: 24px 0;
        }
        .close_button{
            border: none;
            background: transparent;

        }
    }
    .Modal_middle{
        display: flex;
        .inputdrapper{
            width: 50%;
            
        }
        .manageUser_details{
          span{
            padding-bottom: 38px;
            font-weight: 400;
            font-size: 17px;
            line-height: 25px;
            a{
                background: none;
                color:rgba(0, 169, 145, 1);
                font-weight: 400;
                font-size: 17px;
                line-height: 25px;
                padding: 0;
                border: none;
                border-radius: 0;
                &:hover{
                    border-bottom: 1px solid rgba(0, 169, 145, 1);
                }

            }
          }
        }
    }
}

.inputdrapper label {
    font-size: 16px;
    display: block;
    margin-bottom: 5px;
    color: #4E4E4E;
    font-weight: 400;
    position: relative;
    font-family: "Barlow Semi Condensed", sans-serif !important;
}

.inputdrapper .MuiFormControl-root {
    width: 100% !important;
    margin: 0 0 8px 0 !important;
    input{
        border-radius: 4px;
        font-size: 14px;
        font-family: "Barlow Semi Condensed", sans-serif !important;
        padding: 12px 12px !important;
    }
}
.inputdrapper legend{
    display: none;
}
.addDeviceMiddle{
   .field_addDevice{
        display: flex;
        flex-wrap: wrap;
       .innerFields{
            width: 47% !important;
            margin: 0 7px !important;
            label{
                color:rgba(39, 40, 43, 1) ;
                font-weight: 700;
                line-height: 30px;
                font-family: "Barlow Semi Condensed", sans-serif !important;
            }
            .MuiOutlinedInput-notchedOutline{
                border:1px solid rgba(78, 78, 78, 1);
            }
            label+.css-r5in8e-MuiInputBase-root-MuiInput-root {
                margin-top: 4px;
            }
            .MuiInputBase-root{
                // padding: 4px 12px;
             
                .MuiNativeSelect-select{
                    padding-bottom: 14px;
                    padding-left: 10px;
                }
                &::before{
                    border:1px solid rgba(78, 78, 78, 1);
                    height: 50px;
                    border-radius: 6px;
                }
                &::after{
                 display: none;
                }
            }
            label + .css-17tf9hn {
                margin-top: 6px;
            }
       }
   } 
}
.adddeviceFooter{
    // margin-top: 80px;
    margin-top: 32px;
    margin-bottom: 32px;
    .MuiBox-root{
        justify-content: flex-end;
        button{
            margin: 0 8px;
            font-family: "Barlow Semi Condensed", sans-serif !important;

        }
        .saveBtn{
            background: rgba(8, 109, 171, 1);
            font-weight: 700;
            font-size: 18px;
            border: none;
        }
        .cancelBtn{
            color: rgba(8, 109, 171, 1);
            font-weight: 700;
            font-size: 18px;
            border: none;
        }
    }
}

.innerFields {
    .MuiInputLabel-root {
        transform: none !important; // Prevent label from transforming on focus
    }
}

.delte_modal{
    z-index: 9999 !important;
    .MuiBox-root{
      padding:0 32px 0 32px;
      width: 500px;
      .Modal_header{
        height: 78px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        h1{
          font-weight: 500;
          font-size: 24px;
          line-height: 30px;
          color: rgba(39, 40, 43, 1);
        }
      }
      .Modal_middle{
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        p{
          font-weight: 400;
          font-size: 18px;
          color: rgba(52, 64, 84, 1);
        }
      }
      .Modal_footer{
            
        
            .MuiBox-root{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 100px;
            }
        button{
            margin: 0 8px;
            font-family: "Barlow Semi Condensed", sans-serif !important;

        }
        .saveBtn{
            background: rgba(8, 109, 171, 1);
            font-weight: 700;
            font-size: 18px;
            border: none;
        }
        .cancelBtn{
            color: rgba(8, 109, 171, 1);
            font-weight: 700;
            font-size: 18px;
            border: none;
        }
      }
    }
  }
  .customerror{
    margin-top: -5px !important;
    color: #d32f2f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-bottom: 0;
    margin-left: 14px;
    margin-right: 14px;
  }
  .css-wq8qsl-MuiFormHelperText-root {
     color: #d32f2f!important;
  }
  .MuiFormHelperText-root{
    color: #d32f2f!important;
  }

  @media (max-width: 768px) {
      .manageUserTable_wrapper{
        overflow-x: auto;
        & > .MuiBox-root{
            width: 950px;
        }
      }
      .addManageUser{
          .field_addDevice  .innerFields{
            width: 100% !important;
            margin-bottom: 24px !important;
          }
      }
  }