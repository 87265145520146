.header_wrapper{
    background: rgba(3, 107, 173, 1);
    height: 64px;
    border-bottom: 2px solid  rgba(205, 63, 0, 1);
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    .logo{
        img{
            width:105.33px;
            height:32px;
        }
    }
    .navbar{
        padding: 0 0;
        margin: 0 0;
        ul{
            display: flex;
            list-style-type: none;
            align-items: center;
            margin-bottom: 0;
            padding-left: 0;
            li{
                a{
                    margin-left:16px; 
                    img{
                        height: 32px;
                        width: 32px;
                    }
                }
            }
        }
        &.forDesktop{
            display: block;
            @media (max-width:768px){
                display: none;
            }
        }
        &.forMobile{
            display: none;
            @media (max-width:768px){
                display: block;
                .navMobile{
                    display: flex;
                    z-index: 9;
                    position: relative;
                    .profileMobile{
                        padding-right: 35px;
                       li{
                            a{
                                margin-left: 0;
                            }
                       }
                    }
                    .mobilethreeDotsbtn{
                        position: absolute;
                        right: 5px;
                        top: 2px;
                        width: 10px;
                        .threeDotsBtn{
                            padding: 0 0;
                            width: 10px;
                            min-width: auto;
                        }
                    }
                }
                .otherNavMobile{
                    display: flex;
                    flex-direction: column;
                    box-shadow: 0px 4px 4px 0px #00000040;
                    background: #FFFFFF;
                    border-radius:6px;
                    padding: 13px 24px; 
                    li{
                        a{
                            margin-left: 0;
                        }
                   }
                }
            }
           
        }
    }
}

.MuiMenu-paper{
    top:60px !important; 
    // left: 320px !important;
}