.floorMap_wrapper{
    width: 100%;
    // padding: 41px 62px;
    // border-left: 1px solid #000;
    .main_heading{
        border-bottom: 1px solid rgb(230, 236, 245);
        padding:8px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1{
            color:rgba(3, 107, 173, 1);
            font-weight: 700;
            font-size: 32px;
            line-height:38.4px;
        }
    }
    .floorTab{
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        padding: 5px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgb(230, 236, 245);
        ul{
            display: flex;
            list-style-type: none;
            align-items: center;
            height: 100%;
            li{
                a{
                    font-size: 22px;
                    line-height: 26px;
                    font-weight: 400;
                    padding: 4px 4px;
                    border-bottom: 2px solid transparent;
                    margin-right: 10px;
                    color: rgba(78, 78, 78, 1);
                    cursor: pointer;
                    &:hover{
                        border-bottom: 2px solid rgba(3, 107, 173, 1);
                        color: rgba(39, 40, 43, 1);
                    }
                    &.active{
                        border-bottom: 2px solid rgba(3, 107, 173, 1);
                        color: rgba(39, 40, 43, 1);
                    }
                    &.disabled{
                        pointer-events: none;
                        cursor: default;
                    }
                    .alerticon{
                        height: 20px;
                        width: 20px;
                        margin-left: 5px;
                    }
                }
            }
        }
        // .resetBtn{
        //     background: #036bad;
        //     border: none;
        //     color: #fff;
        //     height: 40px;
        //     width: 100px;
        //     border-radius: 5px;
        //     cursor: pointer;
        //     border: 2px solid transparent;
        //     &:hover{
        //         background: #fff;
        //         color: #036bad;
        //         border: 2px solid #000;

        //     }
        // }
    }
    .innerWrapper{
        margin:19px 24px;
        border: 1px solid rgba(230,236,245,1);
        border-radius: 6px;
     
    }.bottom_wrapper{
         padding:0px 16px 45px 16px;
        .floorOneNew{
            &.DeviceBg{
                background-image: url('../../assets/images/DeviceBg.png');
            }
            &.DeviceBgTwo{
                background-image: url('../../assets/images/DeviceBgTwo.png');
            }
            &.addHighlight1{
                background-image: url('../../assets/images/addHighlightOne.png');
            }
            &.addHighlight2{
                background-image: url('../../assets/images/addHighlightTwo.png');
            }
            // max-width: 1044px;
            width: 100%;
            // height: 475px;
            max-width: 1200px;
            height: 700px;
            // background-size: contain;
            background-size: 100% 85%;
            background-repeat: no-repeat;
            position: relative;
            margin: 40px auto;
            
            .mainBtnwrapp{
                display: flex;         
                flex-direction: row;    
                // justify-content: spaceflexbetween; 
                align-items: flex-start;    
                width: 100%;             
                padding: 10px;
                .pushBtns .signBtnDevices:disabled {
                    cursor: not-allowed;
                }
                .pushNew_30{
                    position: absolute;
                    top: 63.7%;
                    left: 16%;
                }
                #trigger-30{
                   
                }
                #trigger-11{
                    position: absolute;
                    top: 19.9%;
                    left: 16%;
                }
                #trigger-14{
                    position: absolute;
                    top: 39.2%;
                    left: 16%;
                }
                .camBtns_1 #trigger-4{
                    position: absolute;
                    top: 49.7%;
                    left: 35.3%;
                }
                .camBtns_2 #trigger-6{
                    position: absolute;
                    top: 49.7%;
                    left: 27%;
                }
                .camBtns_3 #trigger-7{
                    position: absolute;
                    top: 58.7%;
                    left: 35.2%;
                }
                .camBtns_3 #trigger-8{
                    position: absolute;
                    top: 21.8%;
                    left: 26.8%;
                }
                .camBtns_1 #trigger-18{
                    position: absolute;
                    top: 21.9%;
                    left: 26.8%;
                    display: none;
                }
                .camBtns_2 #trigger-20{
                    position: absolute;
                    top: 49.7%;
                    left: 27%;
                }
                .camBtns_3 #trigger-21{
                    position: absolute;
                    top: 49.7%;
                    left: 35.2%;
                }
                .camBtns_4 #trigger-22{
                    position: absolute;
                    top: 21.8%;
                    left: 26.8%;
                }
              
                // .camBtns_1{
                //     #trigger-8{
                //         position: absolute;
                //         top: 31.8%;
                //         left: 26.9%;
                //     }
                //     #trigger-20{
                //         position: absolute;
                //         top: 31.8%;
                //         left: 26.9%
                //     }
                // }
                
                // .camBtns_2{
                   
                    
                //     #trigger-18{
                //         position: absolute;
                //         top: 58.7%;
                //         left: 26.9%;
                //     }
                //     #trigger-21{
                //         position: absolute;
                //         top: 58.8%;
                //         left: 35.2%;
                //     }
                // }
                #trigger-3{
                    position: absolute;
                    top: 2.4%;
                    left: 70.3%;
                }
                #trigger-9{
                    position: absolute;
                    top: 14%;
                    left: 70.2%;
                }
                #trigger-13{
                    position: absolute;
                    top: 25.7%;
                    left: 70.2%;
                }
                #trigger-15{
                    position: absolute;
                    top: 37%;
                    left: 70.3%;
                }
                #trigger-2{
                    position: absolute;
                    top: 49%;
                    left: 70.3%;
                }
                #trigger-5{
                    position: absolute;
                    top: 60.2%;
                    left: 70.3%;
                }
                #trigger-12{
                    position: absolute;
                    top: 72.2%;
                    left: 70.3%;
                }
                #trigger-24{
                    position: absolute;
                    top: 19.9%;
                    left: 16%;
                }
                #trigger-27{
                    position: absolute;
                    top: 39.2%;
                    left: 16%;
                }
                #trigger-17{
                    position: absolute;
                    top: 2.4%;
                    left: 70.3%;
                }
                #trigger-23{
                    position: absolute;
                    top: 14%;
                    left: 70.3%;
                }
                #trigger-26{
                    position: absolute;
                    top: 25.8%;
                    left: 70.3%;
                }
                #trigger-28{
                    position: absolute;
                    top: 37.4%;
                    left: 70.3%;
                }
                #trigger-16{
                    position: absolute;
                    top: 48.4%;
                    left: 70.3%;
                }
                #trigger-19{
                    position: absolute;
                    top: 60.2%;
                    left: 70.3%;
                }
                #trigger-25{
                    position: absolute;
                    top: 72.2%;
                    left: 70.3%;
                }
                .camBtns_4.cmbtn4Parent_29{
                    position: absolute;
                    top: 70%;
                    left: 33.8%;
                }
                .cmbtn4Parent_20{
                    position: absolute;
                    top: 49.8%;
                    left: 26.9%;
                }
                .cmbtn4Parent_21{
                    position: absolute;
                    top: 49.8%;
                    left: 35.2%;
                }
                .cmbtn4Parent_22{
                    position: absolute;
                    top: 22%;
                    left: 26.8%;
                }
                // .pushBtnWrap{
                //     .pushBtns{
                //         position: relative;
                //         height: 200px;
                //         width: 100px;
                //         display: flex;
                //         align-items: center;
                //         justify-content: center;
                //         padding: 0 10px;

                //     }
                // }
                // .cameraBtnsWrapper{
                //     .camBtns{
                //         position: relative;
                //         height: 200px;
                //         width: 100px;
                //         display: flex;
                //         align-items: center;
                //         justify-content: center;
                //         flex-direction: column;
                //         padding: 0 10px;

                //     }
                // }
                // .restBtnsWrapper{
                //     .restBtns{
                //         position: relative;
                //         height: 400px;
                //         min-width: 100px;
                //         display: flex;
                //         flex-direction: column;
                //         align-items: center;
                //         justify-content: normal;
                //         padding: 0 10px;
                //         flex-wrap: wrap;
                    
                //     } 
                // }
            }
            // .DeviceBgTwo .camBtns.camBtns_4{
            //     position: absolute;
            //     top: 22% !important;
            //     left: 26.8%;
            // }
        }
    }
   
    .signBtnDevices{
        border: none;
        background: transparent;
        position: relative;       
        cursor: pointer;
    }
    
    .camerBtnNew{
        background-image: url('../../assets/images/camAni.svg');
        background-repeat: no-repeat; 
        background-size: contain;
        padding: 0 0;
        width: 59px;
        height: 60px;
        cursor: default;
        &.cameraBrokenNew{
            background-image: url('../../assets/images/cameraBrokenNew.svg');
        }
        // &:hover{
            //     background-image: url('../../assets/images/cameraButtonHover.svg');
            //     border-radius:50%; 
            //     width: 44px;
            //     height: 44px;
            // }
            &.activatedNew{
                animation: DissolveNewOne 900ms ease-in-out 200ms infinite;
                
                @keyframes DissolveNewOne {
                    0% {
                        background-image: url('../../assets/images/cameraButtonactivNew1.svg');
                    }
                    33% {
                        background-image: url('../../assets/images/cameraButtonactivNew2.svg');
                    }
                    67%{
                        background-image: url('../../assets/images/cameraButtonactivNew3.svg');
                    }
                    100% {
                        background-image: url('../../assets/images/cameraButtonactivNew1.svg');
                }
            }
        }
        &.cameraBroken{
            background-image: url('../../assets/images/cameraBroken.svg');
        }
        // &.active{
        //     background-image: url('../../assets/images/cameraButtonPressed.svg');
        // }
        // &.one{
        //     bottom: 35%;
        //     left: 8.5%;
        // }
        // &.two{
        //     bottom: 54%;
        //     left: 8.5%;
        // }
        // &.three{
        //     bottom: 54%;
        //     left: 52%;
        // }
        // &.activated:before {
        //     animation:ripple 2s ease-out infinite;
        //     border:solid 3px #00669B;
        //     border-radius: 50%;
        //     bottom:0;
        //     box-sizing:border-box;
        //     content:"";
        //     left:0;
        //     position:absolute;
        //     right:0;
        //     top:0;
        //   }
          
        //   &.activated:after {
        //     animation:ripple 2s ease-out infinite;
        //     border:solid 3px #00669B;
        //     border-radius: 50%;
        //     bottom:0;
        //     box-sizing:border-box;
        //     content:"";
        //     left:0;
        //     position:absolute;
        //     right:0;
        //     top:0;
        //   }
          
        //   @keyframes ripple {
        //     0% {
        //       opacity:.25;
        //     }
        //     100% {
        //       border-radius:2em;
        //       opacity:0;
        //       transform:scale(1.5);
        //     }
        //   }
    }
    .cameraButtonFlip{
        background-image: url('../../assets/images/cameraButtonFlip.svg');
        background-repeat: no-repeat; 
        background-size: cover;
        padding: 0 0;
        width: 44px;
        height: 44px;
        cursor: default;
        // &:hover{
        //     background-image: url('../../assets/images/cameraButtonFlipHover.svg');
        //     border-radius:50%; 
        //     width: 44px;
        //     height: 44px;
        // }
        // &.active{
        //     background-image: url('../../assets/images/cameraButtonFlipPressed.svg');
        // }
        // &.one{
        //     bottom: 35%;
        //     left: 8.5%;
        // }
        // &.two{
        //     bottom: 54%;
        //     left: 8.5%;
        // }
        // &.three{
        //     bottom: 54%;
        //     left: 52%;
        // }
    }
    .commonLightNew{
        background-image: url('../../assets/images/Strobeani.svg');
        background-repeat: no-repeat;
        width: 62px;
        height: 57px;
        background-size: contain;
        padding: 0 0;
        cursor: default;

        // &:hover{
        //     background-image: url('../../assets/images/strobeButtonHover.svg');
        //     border-radius:50%; 
        //     width: 44px;
        //     height: 44px;
        // }
        &.activatedNew{
            animation: SmartAnimateNewOne 600ms ease-in-out 100ms infinite;
            
            @keyframes SmartAnimateNewOne {
                0% {
                    background-image: url('../../assets/images/strobeButtonactivnew1.svg');
                }
                33% {
                    background-image: url('../../assets/images/strobeButtonactivnew2.svg');
                }
                67%{
                    background-image: url('../../assets/images/strobeButtonactivnew3.svg');
                }
                100% {
                    background-image: url('../../assets/images/strobeButtonactivnew1.svg');
            }
        }
        }
        // &.active{
        //     background-image: url('../../assets/images/strobeButtonPressed.svg');
        // }
        // &.activated:before {
        //     animation:ripple 2s ease-out infinite;
        //     border:solid 3px #AD5F04;
        //     border-radius: 50%;
        //     bottom:0;
        //     box-sizing:border-box;
        //     content:"";
        //     left:0;
        //     position:absolute;
        //     right:0;
        //     top:0;
        //   }
          
        //   &.activated:after {
        //     animation:ripple 2s ease-out infinite;
        //     border:solid 3px #AD5F04;
        //     border-radius: 50%;
        //     bottom:0;
        //     box-sizing:border-box;
        //     content:"";
        //     left:0;
        //     position:absolute;
        //     right:0;
        //     top:0;
        //   }
          
        //   @keyframes ripple {
        //     0% {
        //       opacity:.25;
        //     }
        //     100% {
        //       border-radius:2em;
        //       opacity:0;
        //       transform:scale(1.5);
        //     }
        //   }
    }
    .pushBtnNew{
         background-image: url('../../assets/images/PushAni.svg');
        background-repeat: no-repeat;
        width: 63px;
        height: 77px;
        background-size: cover;
        padding: 0 0;
        &.pushBrokenNew{
            background-image: url('../../assets/images/PushAniBroken.svg');
        }
        // &:hover{
        //     background-image: url('../../assets/images/PushButtonHover.svg');
        //     border-radius:50%; 
        //     width: 44px;
        //     height: 44px;
        // }
        // &.active{
        //     background-image: url('../../assets/images/PushButtonPressedActivated.svg');
        // }
        &.activatedNew{
            animation: SmartAnimatePushBtnOneNew 600ms ease-in-out 100ms infinite;
            @keyframes SmartAnimatePushBtnOneNew {
                0% {
                    background-image: url('../../assets/images/pushButtonactivNew1.svg');
                }
                33% {
                    background-image: url('../../assets/images/pushButtonactivNew2.svg');
                }
                67%{
                    background-image: url('../../assets/images/pushButtonactivNew3.svg');
                }
                100% {
                    background-image: url('../../assets/images/pushButtonactivNew1.svg');
            }
        }
        }
        &.two{
            bottom: 75%;
            left: 50%;
        }
    }
    
    .audioIconNew{
        background-image: url('../../assets/images/SoundAni.svg');
        background-repeat: no-repeat;
        width: 62px;
        height: 59px;
        background-size: contain;
        padding: 0 0;
        position: relative;
        // &:hover{
        //     background-image: url('../../assets/images/SpeakerButtonHover.svg');
        //     border-radius:50%; 
        //     width: 44px;
        //     height: 44px;
        // }
        span{
            display: none;
        }
        &.activatedNew{
            animation: SmartAnimateSpeakerOneNew 900ms ease-in-out 200ms infinite;
            @keyframes SmartAnimateSpeakerOneNew {
                0% {
                    background-image: url('../../assets/images/SpeakerButtonactivNew1.svg');
                }
                33% {
                    background-image: url('../../assets/images/SpeakerButtonactivNew2.svg');
                }
                67%{
                    background-image: url('../../assets/images/SpeakerButtonactivNew3.svg');
                }
                100% {
                    background-image: url('../../assets/images/SpeakerButtonactivNew1.svg');
            }
        }
        }
        // &.active{
        //     background-image: url('../../assets/images/SpeakerButtonPressed.svg');
        // }
        // &.one{
        //     bottom: 19%;
        // }
        // &.two{
        //     bottom: 34%;
        // }
        // &.three{
        //     bottom: 49%;
        // }
        // &.four{
        //     bottom: 65%;
        // }
        // &.five{
        //     bottom: 70%;
        //     left: 56%;
        // }
        // &.activated:before {
        //     animation:ripple 2s ease-out infinite;
        //     border:solid 3px #9E00FF;
        //     border-radius: 50%;
        //     bottom:0;
        //     box-sizing:border-box;
        //     content:"";
        //     left:0;
        //     position:absolute;
        //     right:0;
        //     top:0;
        //   }
          
        //   &.activated:after {
        //     animation:ripple 2s ease-out infinite;
        //     border:solid 3px #9E00FF;
        //     border-radius: 50%;
        //     bottom:0;
        //     box-sizing:border-box;
        //     content:"";
        //     left:0;
        //     position:absolute;
        //     right:0;
        //     top:0;
        //   }
          
        //   @keyframes ripple {
        //     0% {
        //       opacity:.25;
        //     }
        //     100% {
        //       border-radius:2em;
        //       opacity:0;
        //       transform:scale(1.5);
        //     }
        //   }
    }

}


.showFloor{
    display: none;
    font-size: 20px;
    font-weight: 600;
    margin-left: 20px;
    &.active{
        display: block;
    }
}
.customTooltip{
    background: #27282B !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 9px 10px !important;
    border-radius: 10px !important;
    margin-bottom:5px !important 

}
.customArrow::before{
    background: #27282B !important;
}

.MuiStack-root{
    margin-left: 50px !important;
}
// .newPrintStyle{
//     position: absolute;
//     top: 0;
//     display: none;
// }

#printWrapper .disnone{
    display: none !important;
}