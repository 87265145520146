.overRideModal{
    z-index: 9999 !important;
    .saveBtn{
        text-transform: capitalize;
        border-radius: 6px;
        padding: 9px 17px;
    }
    .overrideMiddle{
        .field_addDevice{
            flex-direction: column;
            .clickVerify{
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: #323452;
            }
            .verifyCode_wrapper{
                p{
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #4E4E4E;
                    margin-bottom: 9px;
                }
                .textBox{
                    border: 1px solid #76778B;
                    color: #27282B;
                    padding: 10px 10px;
                    border-radius: 6px;
                    margin-right: 26px;
                }
                .verifyBtn{
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 28px;
                    color: #086DAB;
                    background: transparent;
                    padding: 0 0;
                    box-shadow: none;
                    text-transform: capitalize;
                    &.success{
                        color: #037E3A;
                    }
                    &.error{
                        color: #CD3F00;
                    }
                }
            }
        }
    }
}
.MuiSnackbar-anchorOriginTopRight{
    z-index: 99999 !important;
}
.deactiveTooltipbtn{
    padding: 0px 0px;
    background: transparent;
    line-height: 1;
    display: flex;
    align-items: center;
    img.deactiveTooltipimg{
        height: 12px !important;
        margin-right: 5px;
        margin-top: 2px;
    }
}