.profileWrapper {
  width: 100%;

  .main_heading {
    border-bottom: 1px solid rgb(230, 236, 245);
    padding: 8px 24px;

    h1 {
      color: rgba(3, 107, 173, 1);
      font-weight: 700;
      font-size: 32px;
      line-height: 38.4px;
      margin-bottom: 0;
    }
  }

  .menu_bar {
    border-bottom: 1px solid rgb(230, 236, 245);
    padding: 0px 24px;

    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        border: none;
        background: transparent;
        color: rgba(78, 78, 78, 1);
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        padding: 10px 10px;

        &.active {
          border-bottom: 4px solid rgba(3, 107, 173, 1);
          font-weight: 600;
        }
      }
    }
  }

  .userPassdDetail_wrapper {
    min-width: 854px;
    margin-top: 54px;
    display: flex;
    justify-content: center;
    position: relative;

    &.myprofileMedia {
      @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 25px !important;
      }
    }

    .name_wrapper {
      width: 220px;
      height: 290px;
      border: 1px solid rgba(230, 236, 245, 1);
      padding: 30px 16px;
      border-radius: 6px;
      margin-right: 12px;

      .edit_name {
        color: #323452;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .edit_profilePic {
        margin: 15px 0 25px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 145px;
          width: 145px;
          border-radius: 50%;
          text-align: center;
        }
      }

      .name {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-right: 3px;
        }

        button {
          color: rgba(8, 109, 171, 1);
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          border: none;
          background: transparent;
        }
      }
    }

    .update_details {
      width: 600px;
      height: 400px;
      border: 1px solid rgba(230, 236, 245, 1);
      padding: 30px 16px;
      border-radius: 6px;
      margin-right: 12px;

      label {
        font-size: 16px !important;
        font-weight: 400 !important;
        color: rgba(78, 78, 78, 1) !important;
        line-height: 24px !important;
      }
    }

    .update_wrapper {
      width: 448px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgb(230, 236, 245);
      border-radius: 6px;
      padding: 20px 20px;
      margin: 0px 6px;

      .profile-form {
        width: 100%;

        &.dropdownWrapper {
          label {
            margin-bottom: 0 !important;
            color: rgba(78, 78, 78, 1) !important;
          }

          .MuiInputBase-root {
            margin-top: 8px !important;

            &:hover {
              &::before {
                border-bottom: none;
              }
            }

            &::after {
              display: none;
            }
          }

          .MuiNativeSelect-select {
            border: 1px solid rgba(118, 119, 139, 1);
            color: rgba(50, 52, 82, 1) !important;
            padding: 10px 10px !important;
          }

          .MuiSvgIcon-root {
            fill: rgba(50, 52, 82, 1) !important;
          }
        }
      }

      .profile-form h2 {
        font-size: 24px;
        margin-bottom: 15px;
        text-align: left;
        color: #4e4e4e;
        font-weight: 700;
      }

      .profile-form label {
        font-size: 16px;
        display: block;
        margin-bottom: 10px;
        color: #323452;
        font-weight: 400;
        position: relative;
        font-family: "Barlow Semi Condensed", sans-serif !important;
      }

      .profile-form .MuiFormControl-root {
        width: 100% !important;
        margin: 0 0 8px 0 !important;

        input {
          border-radius: 4px;
          font-size: 14px;
          font-family: "Barlow Semi Condensed", sans-serif !important;
          padding: 12px 12px !important;
        }
      }

      .profile-form legend {
        display: none;
      }

      .profile_wrapperbtn {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;

        button.btnSubmit {
          border: none !important;
          background: transparent !important;
          font-size: 16px !important;
          color: #fff !important;
          font-weight: 400 !important;
          line-height: 20px !important;
          padding: 10px 20px !important;
          background-color: #086dab !important;
        }

        button.cancel {
          border: 1px solid #323452;
          border-radius: 5px;
          margin-right: 10px;
        }

        button.save {
          border: 1px solid #003e9c;
          border-radius: 5px;
          background: #003e9c;
          color: #fff;
        }
      }
    }
  }
}

.error_wraperProfile {
  position: absolute;
  margin-top: 0px;
  background: rgb(248 232 234);
  width: 448px;
  padding: 14px 12px;
  border-radius: 6px;
  font-size: 16px;
  color: #27282b;
  font-weight: 400;
  line-height: 24px;
  top: -70px;

  img {
    margin-right: 15px;
  }
}

.success_wraperProfile {
  position: absolute;
  margin-top: 0px;
  background: #edfaea;
  width: 448px;
  padding: 14px 12px;
  border-radius: 6px;
  font-size: 16px;
  color: #27282b;
  font-weight: 400;
  line-height: 24px;
  top: -70px;

  img {
    margin-right: 15px;
  }
}

.password-strength {
  .valid {
    color: #4acf35;
  }
}

.sucssess_Box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.sucssess_Box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 448px;
  margin-bottom: 30px;

  img {
    margin-bottom: 15px !important;
  }

  h2 {
    font-size: 24px;
    color: #323452;
    font-weight: 700;
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
    color: #323452;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 15px;
  }

  span {
    font-size: 14px;
    color: #323452;
    font-weight: 700;
    line-height: 23px;
  }

  .btnLogin {
    color: #00a991;
    font-size: 17px;
    font-weight: 400;
    background: transparent;
    border: none;
  }
}

.uploadFileMiddle {
  max-width: 536px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed rgba(91, 93, 117, 1);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 28px 24px;
  min-height: 145px;
  position: relative;
  margin-bottom: 15px;

  .reactEasyCrop_Container {
    height: 145px;
  }

  .inst {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 15px;
    margin-bottom: 0px;
  }

  .type {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 0px;
  }
}

.profileModal {
  .MuiBox-root {
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    padding-bottom: 0;
    padding-top: 0;

    .MuiFormControl-root {
      width: 32px !important;
      margin: 0 0 0px 0 !important;

      input {
        display: none;
      }

      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  }

  .Modal_header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 0;
      padding: 24px 0;
    }

    .close_button {
      border: none;
      background: transparent;
      color: #345ba9;
    }
  }

  .Modal_footer {
    width: 100%;

    .MuiBox-root {
      flex-direction: row;
      height: 100px;
      justify-content: flex-end;

      button {
        margin-left: 15px;
        width: 77px;
        height: 48px;
        padding: 8px 16px 8px 16px;
        text-transform: none;
        // opacity: 0px;
        font-size: 18px;
        font-weight: 700;
        border: none;
        font-family: Barlow Semi Condensed;
        line-height: 28px;
        text-align: center;
      }
      .btn--save {
        background-color: #086dab;
      }
      .btn--cancel {
        color: #086dab;
      }
    }
  }

  .Modal_middle {
    display: flex;

    .inputdrapper {
      width: 50%;
    }
  }
}

.reactEasyCrop_Image {
  max-width: 100px;
  max-height: 100px;
}

.btn--capture {
  background-color: #086dab !important;
}

.profileError {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(205, 63, 0, 1);
}

.update_pass_mnWrapper {
  display: flex;
  flex-direction: column;

  .updatePassword_myacc {
    display: flex;

    .security_check_wrapper {
      align-items: flex-start !important;

      .securityChecks {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 7px;
        border-bottom: 1px solid rgba(243, 243, 243, 1);

        .sc_heading {
          p {
            margin-bottom: 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: rgba(50, 52, 82, 1);
          }

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: rgba(116, 116, 116, 1);
          }
        }
      }
    }
  }

  .activityLog_wrapper {
    border: 1px solid rgb(230, 236, 245);
    padding: 16px;
    margin: 24px 6px 24px 6px;
    display: flex;
    flex-direction: column;

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: rgba(50, 52, 82, 1);
    }

    .viewLog_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .viewLogs_details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        p {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: rgba(50, 52, 82, 1);
        }

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: rgba(116, 116, 116, 1);
        }

        a {
          text-decoration: none;
          color: rgba(8, 109, 171, 1);
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}

.reactEasyCrop_CropArea {
  border-radius: 50%;
}

.MuiSnackbar-root {
  &.success {
    .MuiPaper-root {
      background-color: #e5f2eb !important;
    }

    .MuiSnackbarContent-message {
      background: url("../../assets/images/check_circle_small.svg");
    }
  }

  &.error {
    .MuiPaper-root {
      background-color: #f8e8ea !important;
    }

    .MuiSnackbarContent-message {
      background: url("../../assets/images/errorSign.svg");
    }
  }

  .MuiPaper-root {
    color: #212831 !important;
    margin-top: 43px;

    .MuiSnackbarContent-message {
      background-repeat: no-repeat;
      background-size: 22px 22px;
      background-position: left center;
      padding: 8px 0 8px 37px;
    }
  }
}

@media (max-width: 768px) {
  .myprofileMedia {
    align-items: center;

    .name_wrapper {
      margin-right: 0px !important;
    }

    .edit_name {
      font-size: 20px;
    }

    .edit_profilePic img {
      width: 100px;
      height: 100px;
    }

    .name button {
      font-size: 12px;
      padding: 6px 10px;
    }

    .update_details {
      padding: 20px 20px !important;
      margin: 20px;
      max-width: 330px;
      width: 100% !important;
      height: 550px !important;

      .addDeviceMiddle {
        max-height: inherit !important;

        .field_addDevice .innerFields {
          width: 100% !important;
        }
      }
    }
  }

  .mypasswordMedia {
    flex-direction: column;

    .update_wrapper {
      flex-direction: column;
      width: auto !important;
      margin: 10px !important;
    }

    .updatePassword_myacc {
      flex-direction: column;
    }
  }

  .myprefrenceMedia {
    .update_pass_mnWrapper {
      flex-direction: column;
    }

    .updatePassword_myacc {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 15px;

      .security_check_wrapper {
        width: auto !important;
      }

      .update_wrapper {
        width: auto !important;
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1198px) {
  .myprofileMedia {
    align-items: center;

    .name_wrapper {
      margin-left: 15px;
    }
  }

  .profileWrapper {
    width: 88% !important;
  }

  .update_pass_mnWrapper {
    width: 100%;
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.button-wrapper {
  text-align: center;
}

.inst {
  font-size: 14px;
  color: #6c757d;
  margin-top: 8px;
}
